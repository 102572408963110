import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { constant, util } from '@helper';
import React from 'react';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Dropdown,
  Form,
  FormInstance,
  Header,
  IconButton,
  InputGroup,
  Modal,
  Nav,
  Navbar,
  Row,
  Schema,
  toaster,
} from 'rsuite';

import './styles.scss';
import logo from '@assets/logo.png';
import logoInverted from '@assets/logo-inverted.png';
import logoInitial from '@assets/logo-initial.png';

import navbarIconUser from '@assets/navbar-dropdown-user.png';
import navbarIconKey from '@assets/navbar-dropdown-key.png';
import navbarIconSupport from '@assets/navbar-dropdown-support.png';
import navbarIconLogout from '@assets/navbar-dropdown-logout.png';

import { Icon } from '@rsuite/icons';
import {
  FaBars,
  FaBell,
  FaCheck,
  FaEye,
  FaEyeSlash,
  FaRegStar,
  FaStar,
} from 'react-icons/fa';
import AuthStorage from '@common/services/authStorage';
import { AuthAPI, MiscAPI } from '@services/api';
import { ChangePasswordPayload } from '@common/ApiTypes';
import constants from '@helper/constants';

const { withRouter } = util;

type State = {
  showChangePassModal: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  notificationTab: string;
  notifications: Array<any>;
  countNewNotification: number;
  starredNotifications: Array<any>;
  changePass: ChangePasswordPayload;
};
const { StringType } = Schema.Types;

class NavBar extends React.Component<any, State> {
  formForgotPassRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formForgotSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      changePass: {
        password: '',
        confirmPassword: '',
      },
      showPassword: false,
      showConfirmPassword: false,
      showChangePassModal: false,
      notificationTab: 'all',
      notifications: [],
      countNewNotification: 0,
      starredNotifications: [],
    };

    this.formForgotPassRef = React.createRef();
    this.formForgotSchemaModel = Schema.Model({
      password: StringType().isRequired('Please fill the required field'),
      confirmPassword: StringType()
        .addRule((value, data) => {
          if (!_.isEqual(value, data.password)) {
            return false;
          }

          return true;
        }, 'The confirm password is not matched.')
        .isRequired('Please fill the required field'),
    });
  }

  componentDidMount(): void {
    const storage = AuthStorage.getInstance();
    if (storage.isAuthenticated()) {
      this.getAllNotifications();
      this.getStarredNotifications();
    }
  }

  private getAllNotifications = async (): Promise<void> => {
    try {
      const res = await MiscAPI.getAllNotifications();
      const data: Array<any> = _.get(res, 'data') || [];
      const opt = _.get(res, 'meta.options');
      this.setState({
        notifications: _.orderBy(data, ['id'], ['desc']),
        countNewNotification: _.get(opt, 'countNewNotif') || 0,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getStarredNotifications = async (): Promise<void> => {
    try {
      const res = await MiscAPI.getStarredNotifications();
      const data: Array<any> = _.get(res, 'data') || [];

      this.setState({
        starredNotifications: _.orderBy(data, ['id'], ['desc']),
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private markAllAsRead = async (): Promise<void> => {
    try {
      const { notifications } = this.state;

      if (notifications.length > 0) {
        await MiscAPI.markAllAsRead();
        toaster.push(util.buildMessage('Marked'));
      }

      this.setState({
        notifications: _.map(notifications, (n: any) => {
          _.set(n, 'isRead', true);
          return n;
        }),
        countNewNotification: 0,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private markAsStarred = async (starred: boolean, id: any): Promise<void> => {
    try {
      if (starred) {
        await MiscAPI.markAsUnStarred(id);
      } else {
        await MiscAPI.markAsStarred(id);
      }

      this.getStarredNotifications();
      this.getAllNotifications();
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private logout = async (): Promise<void> => {
    try {
      const instance = AuthStorage.getInstance();
      const user = instance.getUser();
      const role = _.get(user, 'appRole');

      await AuthAPI.logout();

      instance.clearAll();

      const { navigate } = this.props;
      if (role === 'HIRING_PARTNER') {
        navigate('/login/hiring-partner');
      } else {
        navigate('/login');
      }
      window.location.reload();
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private contactSupport = (): void => {
    window.open('https://api.whatsapp.com/send?phone=6281806484446');
  };

  private accountSettings = (): void => {
    const { navigate } = this.props;
    const storage = AuthStorage.getInstance();
    const role = _.get(storage.getUser(), 'appRole');
    const isUser = role === 'USER';

    if (isUser) {
      navigate('/dashboard/candidate/profile');
    } else {
      navigate('/dashboard/hiring-partner/mycompany');
    }
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onChangePassword = async (): Promise<void> => {
    const { t } = this.props;

    if (!_.invoke(this.formForgotPassRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.changePass.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      const { changePass: payload } = this.state;
      const storage = AuthStorage.getInstance();
      const user = storage.getUser();
      const email = _.get(user, 'email');
      _.set(payload, 'email', email);

      await AuthAPI.changePasswordByUser(payload);

      const { navigate } = this.props;
      storage.clearAll();

      setTimeout(() => {
        toaster.push(
          util.buildMessage(
            t(constant.translation.changePass.formReloginMessageKey),
          ),
        );
      }, 500);

      navigate('/login');
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  renderModal = (): JSX.Element => {
    const { t } = this.props;
    const { showChangePassModal, showConfirmPassword, showPassword } =
      this.state;

    return (
      <Modal
        backdrop="static"
        overflow={false}
        open={showChangePassModal}
        className="changepass-modal"
        onClose={() => {
          this.setState({ showChangePassModal: false });
        }}
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <img
            src={`${`${process.env.PUBLIC_URL}/bg-change-pass.png`}`}
            className="bg-change-pass"
            alt="bg-img"
          />
          <h3>Change Password</h3>
          <p>
            Your new password must be different from previous used password.
          </p>
          <Form
            key="change-pass-form"
            ref={this.formForgotPassRef}
            model={this.formForgotSchemaModel}
            onChange={(v: object) => this.onFormValueChanged(v, 'changePass')}
            formValue={_.get(this.state, 'changePass')}
            fluid
          >
            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formNewPasswordKey)}
              </Form.ControlLabel>
              <InputGroup inside>
                <Form.Control
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your new password here"
                />

                <InputGroup.Button
                  onClick={() => this.setState({ showPassword: !showPassword })}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Button>
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formConfirmPasswordKey)}
              </Form.ControlLabel>
              <InputGroup inside>
                <Form.Control
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Enter your confirm password here"
                />

                <InputGroup.Button
                  onClick={() =>
                    this.setState({
                      showConfirmPassword: !showConfirmPassword,
                    })
                  }
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Button>
              </InputGroup>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.onChangePassword} appearance="primary">
            Change Password
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  render(): JSX.Element | null {
    const { t, location } = this.props;
    const pathname = _.get(location, 'pathname');
    const isHomepage = _.includes(
      [
        '/',
        '/hiring-partner',
        '/candidate',
        '/privacy-policy',
        '/terms-condition',
      ],
      pathname,
    );

    if (!isHomepage) {
      const isAuth = _.includes(
        [
          '/sign-up',
          '/login',
          '/forgot-password',
          '/resend-password-link',
          '/change-password',
          '/basic-info',
          '/email-verification',

          // Hiring partner
          '/login/hiring-partner',
          '/forgot-password/hiring-partner',
          '/resend-password-link/hiring-partner',
          '/change-password/hiring-partner',
          '/sign-up/hiring-partner',
        ],
        pathname,
      );

      const isHiringPartner = String(pathname).includes('hiring-partner');
      if (isAuth) {
        return (
          <Header
            id={`app-header-login${isHiringPartner ? '-hiring-partner' : ''}`}
            className="app-header"
          >
            {!isHiringPartner && (
              <Navbar.Brand>
                <a href="/">
                  <img
                    src={window.innerWidth <= 425 ? logoInitial : logo}
                    alt="app-logo"
                    className="navbar-logo"
                  />
                </a>
              </Navbar.Brand>
            )}

            {isHiringPartner && (
              <Navbar.Brand>
                <a href="/">
                  <img
                    src={window.innerWidth <= 425 ? logoInitial : logoInverted}
                    alt="app-logo"
                    className="navbar-logo"
                  />
                </a>
              </Navbar.Brand>
            )}
          </Header>
        );
      }
    }

    const storage = AuthStorage.getInstance();
    const isAuthenticated = storage.isAuthenticated();
    const user = storage.getUser();
    const isHiringPartner = _.get(user, 'appRole') === 'HIRING_PARTNER';
    const avatarUrl =
      _.get(user, 'avatar.url') ||
      _.get(user, 'company.logo.url') ||
      'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png';
    const {
      notifications,
      notificationTab,
      starredNotifications,
      countNewNotification,
    } = this.state;

    return (
      <>
        {this.renderModal()}
        <Header className="app-header">
          <Navbar appearance="subtle">
            <Nav
              style={{ display: !isAuthenticated ? 'none' : '' }}
              className="menu-mobile-view-authorized"
              onClick={() => {
                const sidebar = document.getElementById('sidebar');
                let app = document.getElementsByClassName('app');

                if (app.length === 0) {
                  app = document.getElementsByClassName('app-hp');
                }

                if (sidebar != null) {
                  sidebar.classList.remove('animate-top-hide');
                  sidebar.classList.add('animate-top');

                  app[0].classList.remove('bg-white-hide');
                  app[0].classList.add('bg-white-active');

                  _.set(sidebar, 'style.display', 'block');
                }
              }}
            >
              <Nav.Item
                icon={
                  <Icon
                    as={FaBars}
                    style={{ color: 'var(--rs-primary-500)' }}
                  />
                }
              />
            </Nav>
            <Navbar.Brand>
              <a
                href="/"
                style={{ cursor: isAuthenticated ? 'default' : 'pointer' }}
              >
                <img
                  src={window.innerWidth <= 425 ? logoInitial : logo}
                  alt="app-logo"
                  className="navbar-logo"
                />
              </a>
            </Navbar.Brand>
            <Nav pullRight className="menu-desktop-view">
              <Nav.Item
                href="/login/hiring-partner"
                target="_self"
                style={{
                  display: pathname === '/hiring-partner' ? 'flex' : 'none',
                }}
              >
                <Button className="login-btn" appearance="primary">
                  {t(constant.translation.home.headerText.login)}
                </Button>
              </Nav.Item>
            </Nav>
            <Nav pullRight className="menu-desktop-view">
              <Nav.Item
                href="/sign-up/hiring-partner"
                target="_self"
                style={{
                  display: pathname === '/hiring-partner' ? 'flex' : 'none',
                }}
              >
                {t(constant.translation.home.headerText.signup)}
              </Nav.Item>
            </Nav>

            {/* Desktop view - Right buttons */}
            <Nav pullRight className="menu-desktop-view">
              <Nav.Item
                href="/login"
                style={{
                  display:
                    isAuthenticated || pathname === '/hiring-partner'
                      ? 'none'
                      : '',
                }}
              >
                <Button className="login-btn" appearance="primary">
                  {t(constant.translation.home.headerText.login)}
                </Button>
              </Nav.Item>
            </Nav>
            <Nav pullRight className="menu-desktop-view">
              <Nav.Item
                href="/sign-up"
                className={pathname === '/sign-up' ? 'active-tab' : ''}
                style={{
                  display:
                    isAuthenticated || pathname === '/hiring-partner'
                      ? 'none'
                      : '',
                }}
              >
                {t(constant.translation.home.headerText.signup)}
              </Nav.Item>
            </Nav>
            <Nav pullRight className="menu-desktop-view">
              <Dropdown
                icon={
                  <Avatar
                    size="md"
                    circle
                    src={`${avatarUrl}`}
                    alt="user-avatar"
                  />
                }
                noCaret
                trigger="hover"
                placement="bottomEnd"
                style={{
                  display: !isAuthenticated ? 'none' : 'block',
                }}
              >
                <Dropdown.Item
                  icon={
                    <Avatar
                      size="xs"
                      src={`${navbarIconUser}`}
                      alt="user-avatar"
                    />
                  }
                  onClick={this.accountSettings}
                >
                  Account Settings
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => this.setState({ showChangePassModal: true })}
                  icon={
                    <Avatar
                      size="xs"
                      src={`${navbarIconKey}`}
                      alt="user-avatar"
                    />
                  }
                >
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item
                  icon={
                    <Avatar
                      size="xs"
                      src={`${navbarIconSupport}`}
                      alt="user-avatar"
                    />
                  }
                  onClick={this.contactSupport}
                >
                  Contact Support
                </Dropdown.Item>
                <Dropdown.Item
                  icon={
                    <Avatar
                      size="xs"
                      src={`${navbarIconLogout}`}
                      alt="user-avatar"
                    />
                  }
                  onClick={this.logout}
                >
                  Log Out
                </Dropdown.Item>
              </Dropdown>
            </Nav>
            <Nav
              pullRight
              className="menu-desktop-view"
              style={{
                display:
                  isHiringPartner || window.innerWidth < 768 ? 'none' : 'block',
              }}
            >
              <Dropdown
                noCaret
                icon={
                  <Badge color="blue" content={countNewNotification}>
                    <Icon as={FaBell} />
                  </Badge>
                }
                trigger="hover"
                placement="bottomEnd"
                className="notif-panel"
                style={{
                  display: !isAuthenticated ? 'none' : 'block',
                }}
              >
                <Dropdown.Item
                  panel
                  style={{
                    width: window.innerWidth.valueOf() * 0.33,
                  }}
                >
                  <Row>
                    <Col xs={15}>
                      <h3 className="notif-header-title">Notifications</h3>
                    </Col>
                    <Col xs={9}>
                      <Button
                        className="mark-as-read"
                        appearance="link"
                        onClick={this.markAllAsRead}
                      >
                        <FaCheck />
                        Mark all as read
                      </Button>
                    </Col>
                  </Row>
                </Dropdown.Item>
                <Dropdown.Item panel className="no-hover">
                  <Row>
                    <Col xs={12} style={{ paddingRight: '0px' }}>
                      <Button
                        className="notif-panel-tab-title"
                        appearance="link"
                        block
                        style={{
                          color:
                            notificationTab === 'all' ? '#212121' : '#595959',
                          borderBottom:
                            notificationTab === 'all' ? '1px solid #000' : '',
                        }}
                        onClick={() =>
                          this.setState({ notificationTab: 'all' })
                        }
                      >
                        All
                      </Button>
                    </Col>
                    <Col xs={12} style={{ paddingLeft: '0px' }}>
                      <Button
                        className="notif-panel-tab-title"
                        appearance="link"
                        block
                        style={{
                          color:
                            notificationTab === 'starred'
                              ? '#212121'
                              : '#595959',
                          borderBottom:
                            notificationTab === 'starred'
                              ? '1px solid #000'
                              : '',
                        }}
                        onClick={() =>
                          this.setState({ notificationTab: 'starred' })
                        }
                      >
                        Starred
                      </Button>
                    </Col>
                  </Row>
                </Dropdown.Item>
                <Dropdown.Item divider />

                {/* Notification Items */}
                {_.map(
                  notificationTab === 'all'
                    ? notifications
                    : starredNotifications,
                  (n) => (
                    <Dropdown.Item
                      panel
                      key={n.id}
                      className="card-item"
                      style={{
                        backgroundColor: !n.isRead ? '#F7FBFF' : 'auto',
                      }}
                    >
                      <Row>
                        <Col xs={4}>
                          <Avatar
                            circle
                            size="md"
                            src={`${_.get(n, 'logo.url')}`}
                            alt="user-avatar"
                          />
                        </Col>
                        <Col xs={17}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: _.get(n, 'description'),
                            }}
                          />
                        </Col>
                        <Col xs={3}>
                          <IconButton
                            onClick={() =>
                              this.markAsStarred(n.isStarred, n.id)
                            }
                            appearance="default"
                            size="md"
                            className="star-btn"
                            icon={
                              <Icon
                                as={!_.get(n, 'isStarred') ? FaRegStar : FaStar}
                              />
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={4} />
                        <Col xs={18}>
                          <Button
                            className="card-btn btn-tg-primary"
                            onClick={async (): Promise<void> => {
                              if (n.url) {
                                await MiscAPI.markAsRead(n.id);
                                window.open(n.url, '_self');
                              }
                            }}
                          >
                            See Detail
                          </Button>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                  ),
                )}

                {(notificationTab === 'all'
                  ? notifications
                  : starredNotifications
                ).length === 0 && (
                  <img
                    alt="notif-noresult"
                    src={`${process.env.PUBLIC_URL}/notif-noresult.png`}
                    className="notif-noresult"
                  />
                )}
              </Dropdown>
            </Nav>

            {/* Desktop view - Left buttons */}
            <Nav className="menu-desktop-view">
              <Nav.Item
                className={
                  pathname === '/' || pathname === '/candidate'
                    ? 'active-tab'
                    : ''
                }
                href="/"
                style={{ display: isAuthenticated ? 'none' : 'block' }}
              >
                {t(constant.translation.home.headerText.candidate)}
              </Nav.Item>
            </Nav>
            <Nav className="menu-desktop-view">
              <Nav.Item
                href="/hiring-partner"
                className={pathname === '/hiring-partner' ? 'active-tab' : ''}
                style={{ display: isAuthenticated ? 'none' : 'block' }}
              >
                {t(constant.translation.home.headerText.hiringPartner)}
              </Nav.Item>
            </Nav>

            {/* Profile avatar */}
            <Nav pullRight className="menu-mobile-view-authorized">
              <Nav.Item
                onClick={this.accountSettings}
                style={{
                  display: !isAuthenticated ? 'none' : '',
                }}
              >
                <Avatar
                  size="md"
                  circle
                  src={`${avatarUrl}`}
                  alt="user-avatar"
                />
              </Nav.Item>
            </Nav>
            <Nav pullRight className="menu-mobile-view-authorized">
              <Nav.Item
                href="#"
                style={{
                  display: !isAuthenticated ? 'none' : '',
                }}
                icon={<Icon as={FaBell} />}
              />
            </Nav>

            {/* Hamburger menu - Unauthorized */}
            <Nav
              pullRight
              style={{ display: isAuthenticated ? 'none' : '' }}
              className="menu-mobile-view"
              onClick={() => {
                const sidebar = document.getElementById('sidebar');
                const app = document.getElementsByClassName('app');

                if (sidebar != null) {
                  sidebar.classList.remove('animate-top-hide');
                  sidebar.classList.add('animate-top');

                  app[0].classList.remove('bg-white-hide');
                  app[0].classList.add('bg-white-active');

                  _.set(sidebar, 'style.display', 'block');
                }
              }}
            >
              <Nav.Item
                icon={
                  <Icon
                    as={FaBars}
                    style={{ color: 'var(--rs-primary-500)' }}
                  />
                }
              />
            </Nav>
          </Navbar>
        </Header>
      </>
    );
  }
}

export default withTranslation()(withRouter(NavBar));
