import _ from 'lodash';
import parse from 'html-react-parser';
import { Icon } from '@rsuite/icons';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { constant, util } from '@helper';
import AuthStorage from '@common/services/authStorage';
import {
  Avatar,
  AvatarGroup,
  Badge,
  Button,
  Carousel,
  Col,
  Divider,
  FlexboxGrid,
  Grid,
  Panel,
  Row,
  Stack,
  Tooltip,
  Whisper,
  toaster,
} from 'rsuite';
import {
  FaArrowRight,
  FaBuilding,
  FaClock,
  FaMapMarkerAlt,
} from 'react-icons/fa';

import '../styles.scss';

import { Promise } from 'bluebird';
import { CompanyAPI, MiscAPI } from '@services/api';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import InfiniteScroll from 'react-infinite-scroller';
import dummy from './dummy.json';
import hiringLogo from '../hiring-logo.json';

const { withRouter } = util;

type State = {
  testimonyData: Array<object>;
  testimonyPageIndex: number;
  stats: any | object;

  results: Array<object | any>;
  loadingCards: boolean;
};

class CandidateHomeScreen extends React.Component<any, State> {
  testimonyTimer: NodeJS.Timer;

  constructor(props: any) {
    super(props);

    this.state = {
      testimonyData: dummy,
      testimonyPageIndex: 0,
      loadingCards: true,
      stats: {},
      results: [],
    };

    this.testimonyTimer = setInterval(this.onTestimonyNextBtnClick, 5000);
  }

  async componentDidMount(): Promise<void> {
    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'white');
    }

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.searchJobs();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getStats();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  componentWillUnmount(): void {
    clearInterval(this.testimonyTimer);
  }

  private searchJobs = async (): Promise<void> => {
    try {
      const res = await CompanyAPI.searchJobs('', {}, 1, 8);
      const results: Array<any> = _.get(res, 'data') || [];

      this.setState({
        loadingCards: false,
        results,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getStats = async (): Promise<void> => {
    try {
      const res = await MiscAPI.getStatistics();
      const stats = _.get(res, 'data');

      this.setState({
        stats,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  onTestimonyPrevBtnClick = (
    e: any,
    { testimonyPageIndex, testimonyData } = this.state,
  ): void => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }

    let pageIndex = 0;

    if (testimonyPageIndex > 0) {
      pageIndex = testimonyPageIndex - 1;
    } else {
      pageIndex = testimonyData.length - 1;

      if (window.innerWidth <= 768 && window.innerWidth > 425) {
        pageIndex = Math.ceil(testimonyData.length / 2) - 1;
      } else if (window.innerWidth > 768) {
        pageIndex = Math.ceil(testimonyData.length / 3) - 1;
      }
    }

    this.setState({
      testimonyPageIndex: pageIndex,
    });
  };

  onTestimonyNextBtnClick = (
    e: any,
    { testimonyPageIndex, testimonyData } = this.state,
  ): void => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }

    let pageIndex = 0;

    if (window.innerWidth <= 425) {
      if (testimonyData.length !== testimonyPageIndex + 1) {
        pageIndex = testimonyPageIndex + 1;
      }
    } else if (window.innerWidth <= 768) {
      if (Math.ceil(testimonyData.length / 2) !== testimonyPageIndex + 1) {
        pageIndex = testimonyPageIndex + 1;
      }
    } else if (window.innerWidth > 768) {
      if (Math.ceil(testimonyData.length / 3) !== testimonyPageIndex + 1) {
        pageIndex = testimonyPageIndex + 1;
      }
    }

    this.setState({
      testimonyPageIndex: pageIndex,
    });
  };

  onJobCategoryClicked = (filterBy: string): void => {
    const { navigate } = this.props;

    navigate('/job-categories', {
      state: { filterBy },
    });
  };

  renderTop = (): React.ReactNode => (
    <Grid fluid className="home-static-container" id="top-candidate-home">
      <Row>
        <Col
          lg={11}
          md={11}
          sm={24}
          xs={24}
          className="home-static-item-container"
        >
          <div>
            <h1>
              Apply.
              <br />
              Showcase
              <br />
              Your skill.
              <br />
              <div>Get hired.</div>
            </h1>
            <p>
              Talent Growth helps you to find and land on local & global
              internship and full time opportunities through skill based hiring.
            </p>
            <Button
              appearance="default"
              onClick={() => window.open('/sign-up', '_self')}
            >
              Join Us&nbsp;&nbsp;
              <Icon as={FaArrowRight} />
            </Button>

            <img src="arrow_yellow.png" alt="arrow-yellow-icon" />
          </div>
        </Col>

        <Col
          lg={13}
          md={13}
          sm={0}
          xs={0}
          className="home-static-item-container top-home"
        >
          <Col xs={12} lg={12} md={12}>
            <img src="bg-home-person-1.png" alt="person1" />
          </Col>
          <Col xs={12} lg={12} md={12}>
            <img src="bg-home-person-2.png" alt="person2" />
          </Col>
          <Col xs={12} lg={13} md={13}>
            <img src="bg-home-person-3.png" alt="person3" />
          </Col>
          <Col xs={12} lg={11} md={11}>
            <img src="bg-home-person-4.png" alt="person4" />
          </Col>
        </Col>
      </Row>
    </Grid>
  );

  renderTutorial = (): React.ReactNode => (
    <Grid fluid className="home-static-container">
      <Row>
        <Col
          lg={16}
          md={24}
          sm={24}
          xs={24}
          className="home-static-item-container"
          id="tutorial-item-container"
        >
          <h1>
            <img
              src="/tutorial-title-decoration.png"
              className="decoration"
              alt="decoration"
            />
            A place to find <br />
            your dream job
          </h1>
          <Row>
            <Col xs={24} sm={12}>
              <Panel bordered>
                <Avatar circle>1</Avatar>
                <h5>Fill in profile & upload your CV</h5>
                <p>
                  Fill in your personal information and upload your best CV and
                  portfolio.
                </p>
              </Panel>
            </Col>
            <Col xs={24} sm={12}>
              <Panel bordered>
                <Avatar circle>2</Avatar>
                <h5>Apply for the job you want</h5>
                <p>
                  Simply click &quot;Apply Job&quot; to continue to the next
                  step!
                </p>
              </Panel>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              <Panel bordered>
                <Avatar circle>3</Avatar>
                <h5>Skill based screening</h5>
                <p>
                  After applying for a position, you will be assigned to a basic
                  Math and English test. Once you pass, you will be given a case
                  study prior to interview stage.
                </p>
              </Panel>
            </Col>
            <Col xs={24} sm={12}>
              <Panel bordered>
                <Avatar circle>4</Avatar>
                <h5>Hired and ready to onboard!</h5>
                <p>
                  Congratulations! Finally, you&apos;ve been hired for your
                  dream jobs! We wish you the best in your future job. Ready,
                  set, and grow!
                </p>
              </Panel>
            </Col>
          </Row>
        </Col>
        <Col lg={8} md={24} sm={24} xs={24} id="tutorial-item-container">
          <Col lg={24} md={12} sm={12} xs={12}>
            <img
              src="/img-tutorial-1.png"
              style={{ width: '100%' }}
              alt="tutorial-bg-1"
            />
          </Col>
          <Col lg={24} md={12} sm={12} xs={12}>
            <img
              src="/img-tutorial-2.png"
              style={{ width: '100%' }}
              alt="tutorial-bg-2"
            />
          </Col>
        </Col>
      </Row>
    </Grid>
  );

  renderReasonJoin = ({ stats } = this.state): React.ReactNode => (
    <Grid
      className="home-static-container"
      id="candidate-reason-join"
      style={{ backgroundColor: '#F1C4FF', width: '100%' }}
    >
      <h1>
        <img src="blue-star.png" alt="blue-star-logo" />
        <img src="yellow-paint-3.png" alt="yellow-paint-3" />
        Why Talent Growth?
      </h1>
      <p className="title-desc">
        How we help you to accelerate your job application process.
      </p>

      <br />

      <Row>
        <Col md={8} sm={24} xs={24} lg={8}>
          <Stack direction="column" spacing={0}>
            <Stack.Item>
              <Panel bordered style={{ position: 'relative' }}>
                <img
                  style={{ position: 'absolute', width: 84, top: '4%' }}
                  src="real-work-exp-icon.png"
                  alt="networking-logo"
                />
                <h5>Real work experience</h5>
                <br />
                <p>
                  There are often differences between what is learned in college
                  and what is required in the workplace. Professional internship
                  experience is required for a more promising career path in the
                  future.
                </p>
                <br />
              </Panel>
            </Stack.Item>

            <Stack.Item className="line-divider">
              <Divider vertical />
            </Stack.Item>

            <Stack.Item>
              <Panel bordered>
                <Row>
                  <Col xs={4} id="candidate-item-reason-join-us">
                    <img
                      src="reason-join-networking-logo.png"
                      alt="networking-logo"
                    />
                  </Col>
                  <Col xs={2} />
                  <Col xs={16} id="candidate-item-reason-join-us">
                    <h5>Networking</h5>
                    <br />
                    <p>
                      Making connections with several professionals in many
                      fields will broaden your options for your future job path.
                    </p>
                  </Col>
                </Row>
                <br />
              </Panel>
            </Stack.Item>
          </Stack>
        </Col>

        <Col
          md={8}
          sm={24}
          xs={24}
          lg={8}
          style={{ marginTop: window.innerWidth <= 425 ? '3vh' : '0px' }}
        >
          <Stack direction="column" spacing={0}>
            <Stack.Item>
              <Panel bordered style={{ position: 'relative' }}>
                <img
                  style={{ position: 'absolute', width: 84, top: '4%' }}
                  src="hiring-partner-icon.png"
                  alt="hiring-partner-logo"
                />
                <h5>{_.get(stats, 'nbOfCompanies')}+ Hiring partners</h5>
                <br />
                <p>
                  At Talent Growth, you can pursue an internship or even a
                  full-time position with a variety of local to international
                  companies/start-up.
                </p>
                <br />
              </Panel>
            </Stack.Item>

            <Stack.Item className="line-divider">
              <Divider vertical />
            </Stack.Item>

            <Stack.Item>
              <Panel bordered>
                <Row>
                  <Col xs={4} id="candidate-item-reason-join-us">
                    <img
                      src="reason-join-global-company-logo.png"
                      alt="global-company-logo"
                    />
                  </Col>
                  <Col xs={2} />
                  <Col xs={16} id="candidate-item-reason-join-us">
                    <h5>Global companies</h5>
                    <br />
                    <p>
                      For those who are seeking global experiences, there are
                      numerous opportunities at global companies from Malaysia,
                      Singapore, France, and many more!
                    </p>
                  </Col>
                </Row>
                <br />
              </Panel>
            </Stack.Item>

            <Stack.Item className="line-divider">
              <Divider vertical />
            </Stack.Item>

            <Stack.Item>
              <Panel bordered>
                <Row>
                  <Col xs={4} id="candidate-item-reason-join-us">
                    <img
                      src="reason-join-local-company-logo.png"
                      alt="local-company-logo"
                    />
                  </Col>
                  <Col xs={2} />
                  <Col xs={16} id="candidate-item-reason-join-us">
                    <h5>Local companies</h5>
                    <br />
                    <p>
                      If you are more keen toward local companies, worry not, we
                      also have numerous opportunities at local companies!
                    </p>
                  </Col>
                </Row>
                <br />
              </Panel>
            </Stack.Item>
          </Stack>
        </Col>

        <Col
          md={8}
          sm={24}
          xs={24}
          lg={8}
          style={{
            marginTop: window.innerWidth <= 425 ? '3vh' : 'inherent',
          }}
        >
          <Stack direction="column" spacing={0}>
            <Stack.Item>
              <Panel bordered style={{ position: 'relative' }}>
                <img
                  style={{ position: 'absolute', width: 84, top: '4%' }}
                  src="remote-work-icon.png"
                  alt="remote-work-logo"
                />
                <h5>Remote & WFO available!</h5>
                <br />
                <p>
                  Don&lsquo;t feel like working from office? Don&lsquo;t worry,
                  we have some remote opportunities for you! Prefer hybrid to
                  avoid boring working dynamic? It&lsquo;s totally possible!
                  When browsing for a job, just adjust it to match your
                  preferences.
                </p>
                <br />
              </Panel>
            </Stack.Item>

            <Stack.Item className="line-divider">
              <Divider vertical />
            </Stack.Item>

            <Stack.Item>
              <Panel bordered>
                <Row>
                  <Col xs={4} id="candidate-item-reason-join-us">
                    <img
                      src="reason-join-allowance-logo.png"
                      alt="allowance-logo"
                    />
                  </Col>
                  <Col xs={2} />
                  <Col xs={16} id="candidate-item-reason-join-us">
                    <h5>Allowance</h5>
                    <br />
                    <p>
                      Say NO to unpaid jobs! We curated trusted job
                      opportunities that compensate you well for your hard work!
                    </p>
                  </Col>
                </Row>
                <br />
              </Panel>
            </Stack.Item>
          </Stack>
        </Col>
      </Row>
    </Grid>
  );

  renderMeetOurHiringPartner = (): React.ReactNode => (
    <Grid
      className="home-static-container meeting-hiring-partners-container"
      fluid
    >
      <Row>
        <Col
          xs={24}
          className="home-static-item-container"
          style={{ backgroundColor: '#C3DCFF' }}
        >
          <div id="meeting-hiring-partners">
            <img
              src="/white-star.png"
              className="white-star"
              alt="white-star-decor"
            />
            <img
              src="/yellow-paint.png"
              className="yellow-paint"
              alt="yellow-paint-decor"
            />
            <h1>Meet Our Hiring Partners</h1>
            <p className="title-desc">
              More than 80 companies from Indonesia, Malaysia,
              <br /> Singapore, France and many more!
            </p>
            <br />

            <AvatarGroup
              style={{ gap: '.75em' }}
              stack
              spacing={window.innerWidth < 768 ? 6 : 24}
            >
              {window.innerWidth > 768
                ? _.map(hiringLogo.slice(0, 9), (logo) => (
                    <Avatar
                      circle
                      key={logo.imageUrl}
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))
                : _.map(hiringLogo.slice(0, 5), (logo) => (
                    <Avatar
                      key={logo.imageUrl}
                      circle
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))}
            </AvatarGroup>
            <AvatarGroup
              style={{ gap: '.75em' }}
              stack
              spacing={window.innerWidth < 768 ? 6 : 24}
            >
              {window.innerWidth > 768
                ? _.map(hiringLogo.slice(9, 16), (logo) => (
                    <Avatar
                      circle
                      key={logo.imageUrl}
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))
                : _.map(hiringLogo.slice(5, 10), (logo) => (
                    <Avatar
                      key={logo.imageUrl}
                      circle
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))}
            </AvatarGroup>
            <AvatarGroup
              style={{ gap: '.75em' }}
              stack
              spacing={window.innerWidth < 768 ? 6 : 24}
            >
              {window.innerWidth > 768
                ? _.map(hiringLogo.slice(16, 22), (logo) => (
                    <Avatar
                      circle
                      key={logo.imageUrl}
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))
                : _.map(hiringLogo.slice(10, 15), (logo) => (
                    <Avatar
                      key={logo.imageUrl}
                      circle
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))}
            </AvatarGroup>
            {window.innerWidth <= 768 && (
              <AvatarGroup stack spacing={window.innerWidth < 768 ? 8 : 36}>
                {_.map(hiringLogo.slice(15, 20), (logo) => (
                  <Avatar
                    key={logo.imageUrl}
                    circle
                    size="lg"
                    src={logo.imageUrl}
                    alt="logo-startup"
                  />
                ))}
              </AvatarGroup>
            )}
            {window.innerWidth <= 768 && (
              <AvatarGroup stack spacing={window.innerWidth < 768 ? 8 : 36}>
                {_.map(hiringLogo.slice(20, 22), (logo) => (
                  <Avatar
                    key={logo.imageUrl}
                    circle
                    size="lg"
                    src={logo.imageUrl}
                    alt="logo-startup"
                  />
                ))}
              </AvatarGroup>
            )}
            <br />
          </div>
        </Col>
      </Row>
    </Grid>
  );

  renderTestimonyCarousel = (data: Array<object>): React.ReactNode => {
    let size = 3;

    if (window.innerWidth < 768 && window.innerWidth > 0) {
      size = 1;
    } else if (window.innerWidth < 1024 && window.innerWidth >= 768) {
      size = 2;
    } else {
      size = 3;
    }
    const chunks = _.chunk(data, size);

    return (
      <Grid className="home-static-container" id="candidate-testimony">
        <img
          src="/yellow-paint-2.png"
          className="yellow-paint"
          alt="yellow-paint-decor"
        />
        <h1>Hear What They Said About Us</h1>
        <p className="title-desc">
          Have a glance at what these successful applicants
          <br /> say after landing on their dream jobs with us.
        </p>

        <a href="#" onClick={this.onTestimonyPrevBtnClick}>
          <img src="/prev-btn.png" className="prev-btn" alt="prev-btn-decor" />
        </a>
        <a href="#" onClick={this.onTestimonyNextBtnClick}>
          <img src="/next-btn.png" className="next-btn" alt="next-btn-decor" />
        </a>

        <Carousel
          className="slider"
          autoplay
          autoplayInterval={5000}
          activeIndex={_.get(this.state, 'testimonyPageIndex')}
        >
          {_.map(chunks, (c: Array<object>, carouselIdx: number) => (
            <Grid fluid key={`carousel-item-${carouselIdx}`}>
              <Row>
                {_.map(c, (testimony: object, index: number) => (
                  <Col
                    md={12}
                    sm={24}
                    xs={24}
                    lg={8}
                    key={`${_.get(testimony, 'imageUrl')}-${index}`}
                  >
                    <img
                      className="slider-item-avatar img-object-fit-cover"
                      src={_.get(testimony, 'imageUrl')}
                      alt="avatar"
                    />
                    <Panel bordered>
                      {_.size(_.get(testimony, 'paragraph', '')) > 254 ? (
                        <Whisper
                          followCursor
                          speaker={
                            <Tooltip>
                              {parse(_.get(testimony, 'paragraph', ''))}
                            </Tooltip>
                          }
                        >
                          <p>{parse(_.get(testimony, 'paragraph', ''))}</p>
                        </Whisper>
                      ) : (
                        <p>{parse(_.get(testimony, 'paragraph', ''))}</p>
                      )}
                      <div className="job-title-container">
                        <h5>{_.get(testimony, 'name')}</h5>
                        <p>{parse(_.get(testimony, 'jobTitle', ''))}</p>
                      </div>
                    </Panel>
                  </Col>
                ))}
              </Row>
            </Grid>
          ))}
        </Carousel>
      </Grid>
    );
  };

  renderJobCategories = (): React.ReactNode => (
    <Grid className="home-static-container">
      <h1>Job Categories</h1>
      <p className="title-desc" id="job-categories">
        Be prepared to apply for more than 1,000 positions being offered by
        <br /> more than 80 start up partners on a national and worldwide scale.
      </p>

      <Grid fluid>
        <Row id="job-categories" style={{ paddingTop: '8px' }}>
          <Col md={8} sm={24} xs={24} lg={8}>
            <Panel>
              <h5>Global Internship</h5>
              <Button
                onClick={() => this.onJobCategoryClicked('internship')}
                appearance="default"
              >
                See More&nbsp;&nbsp;
              </Button>
            </Panel>
          </Col>
          <Col md={8} sm={24} xs={24} lg={8}>
            <Panel>
              <h5>Local Internship</h5>
              <Button
                onClick={() => this.onJobCategoryClicked('internship')}
                appearance="default"
              >
                See More&nbsp;&nbsp;
              </Button>
            </Panel>
          </Col>
          <Col md={8} sm={24} xs={24} lg={8}>
            <Panel>
              <h5>Full Time Job</h5>
              <Button
                onClick={() => this.onJobCategoryClicked('fulltime')}
                appearance="default"
              >
                See More&nbsp;&nbsp;
              </Button>
            </Panel>
          </Col>
        </Row>
      </Grid>
    </Grid>
  );

  renderDiscoverCareerSuitsYou = (): React.ReactNode => (
    <Grid className="home-static-container discover-career-container">
      <h1>Discover a career suit you</h1>
      <br />
      <br />
      <br />

      <Grid fluid>
        <Row>
          <Col xs={24}>{this.renderSearchResults()}</Col>
        </Row>
        <br />
        <Row>
          <Col xs={10} />
          <Col xs={8}>
            <a href="#" onClick={() => this.onJobCategoryClicked('fulltime')}>
              <span>View More Jobs</span>&nbsp;&nbsp;
              <FaArrowRight />
            </a>
          </Col>
          <Col xs={6} />
        </Row>
      </Grid>
    </Grid>
  );

  renderSearchResults = (): React.ReactNode => {
    const { results, loadingCards } = this.state;
    const { t } = this.props;
    const width = window.innerWidth;

    let size = 4;
    let threshold = 1 * window.innerHeight;
    if (width <= 425) {
      size = 1;
    } else if (width <= 959) {
      size = 2;
    } else if (width >= 960 && width <= 1280) {
      size = 3;
    } else {
      threshold = 300;
      size = 4;
    }
    const chunked = _.chunk(results, size);

    return (
      <InfiniteScroll
        pageStart={0}
        threshold={threshold}
        loadMore={() => this.searchJobs()}
        hasMore={false}
        loader={
          <FlexboxGrid key="load-more">
            <FlexboxGridItem
              key="load-more-item-1"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={6}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-2"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={6}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-3"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={6}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-4"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={6}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
          </FlexboxGrid>
        }
      >
        {loadingCards && (
          <Row>
            {_.range(0, 8).map((i: number) => (
              <Col key={`loader-${i}`} xs={24} sm={12} md={12} lg={6}>
                <PlaceholderGraph active />
              </Col>
            ))}
          </Row>
        )}

        {!loadingCards && results.length === 0 && (
          <p className="no-results-text">No results</p>
        )}

        {_.map(chunked, (data: Array<any>, index: number) => (
          <Row
            className="search-results-row"
            key={`row-searchresults-${index}`}
          >
            {_.map(data, (obj: any, idx: number) => {
              const employmentStatus = _.get(obj, 'employmentStatus.0.name');
              const company = _.get(obj, 'company');
              const companyLogo = _.get(obj, 'company.logo.url');
              const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
              const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
              const duration = _.get(obj, 'duration');
              const jobId = _.get(obj, 'id');
              const isHot = _.get(obj, 'isHot');
              const isActive = _.get(obj, 'active');
              const workingTypes: Array<any> = _.get(obj, 'workingTypes') || [];

              return (
                <Col
                  key={`col-searchresults-${idx}`}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={6}
                >
                  <Panel className="search-result-card">
                    <Stack
                      direction="column"
                      spacing={20}
                      style={{ alignItems: 'flex-start' }}
                    >
                      {/* Badge */}
                      <Stack spacing={8}>
                        {isHot ? <Badge content="New" /> : <span />}
                        <Badge
                          className={
                            employmentStatus === 'Full Time'
                              ? 'rs-badge-fulltime'
                              : ''
                          }
                          content={employmentStatus}
                        />
                        {workingTypes.map((val: any) => (
                          <Badge
                            key={`workingTypes-${val.name}`}
                            content={val.name}
                          />
                        ))}
                      </Stack>

                      {/* Company Information */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={8}>
                            <Avatar
                              circle
                              size="lg"
                              src={companyLogo}
                              alt="logo-startup"
                            />
                          </Col>
                          <Col xs={16} className="company-info">
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'name')}</b>
                                </Tooltip>
                              }
                            >
                              <p>{_.get(company, 'name')}</p>
                            </Whisper>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(obj, 'title')}</b>
                                </Tooltip>
                              }
                            >
                              <b>{_.get(obj, 'title')}</b>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Job Details */}
                      <Stack direction="column" spacing={6}>
                        <Row>
                          <Col xs={3}>
                            <FaMapMarkerAlt />
                          </Col>
                          <Col xs={21}>
                            <p>{_.get(company, 'region')}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaClock />
                          </Col>
                          <Col xs={21}>
                            {duration > 0 && <p>{duration} month(s)</p>}
                            {duration === 0 && <p>Full Time</p>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaBuilding />
                          </Col>
                          <Col xs={21}>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'industry.name')}</b>
                                </Tooltip>
                              }
                            >
                              <p className="industry-name">
                                {_.get(company, 'industry.name')}
                              </p>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Salary range */}
                      <Stack spacing={6}>
                        <p className="salary-range">
                          {util.formatSalaryRange(salaryLow, salaryHigh)}
                        </p>
                      </Stack>

                      {/* Job Card Buttons */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={24}>
                            <Button
                              className="btn-tg-primary"
                              block
                              appearance="primary"
                              disabled={!isActive}
                              onClick={() => {
                                // eslint-disable-next-line max-len
                                const url = `/dashboard/candidate/jobs/${jobId}`;
                                window.open(url);
                              }}
                            >
                              {isActive
                                ? t(
                                    constant.translation.searchJob
                                      .formApplyButtonKey,
                                  )
                                : 'Closed'}
                            </Button>
                          </Col>
                        </Row>
                      </Stack>
                    </Stack>
                  </Panel>
                </Col>
              );
            })}
          </Row>
        ))}
      </InfiniteScroll>
    );
  };

  renderJoinUs = (): React.ReactNode => (
    <Grid className="home-static-container">
      <Row>
        <Col xs={24} className="home-static-item-container">
          <div>
            <p>So, what are you waiting for?</p>
            <h5>
              Every second counts.
              <br /> Register and get hired now.
            </h5>
            <Button
              appearance="default"
              onClick={() => window.open('/sign-up', '_self')}
            >
              Join Us Now&nbsp;&nbsp;
              <Icon as={FaArrowRight} />
            </Button>
          </div>
        </Col>
      </Row>
    </Grid>
  );

  render(): React.ReactNode {
    const { testimonyData: data } = this.state;
    const storage = AuthStorage.getInstance();
    const isAuthenticated = storage.isAuthenticated();

    if (isAuthenticated) {
      return <Navigate to="/dashboard/candidate/home" replace />;
    }

    return (
      <div>
        {/* Render Banner (Top component) */}
        {this.renderTop()}

        {/* Render job categories */}
        {this.renderJobCategories()}

        {/* Tutorial to find job */}
        {this.renderTutorial()}

        {/* Meet our hiring partner */}
        {this.renderMeetOurHiringPartner()}

        {/* Why need join us? */}
        {this.renderReasonJoin()}

        {/* Testimony carousel */}
        {this.renderTestimonyCarousel(data)}

        {/* Render discover career suits you */}
        {this.renderDiscoverCareerSuitsYou()}

        {/* Join us card */}
        {this.renderJoinUs()}
      </div>
    );
  }
}

export default withRouter(withTranslation()(CandidateHomeScreen));
