/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Affix,
  Avatar,
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Grid,
  IconButton,
  Modal,
  Nav,
  Panel,
  Row,
  Schema,
  Stack,
  Timeline,
  Uploader,
  toaster,
} from 'rsuite';

import AuthStorage from '@common/services/authStorage';
import { constant, util } from '@helper';
import { DocumentAPI, JobApplicationAPI, MiscAPI } from '@services/api';
import _ from 'lodash';
import moment from 'moment';
import {
  FaBuilding,
  FaChevronLeft,
  FaChevronRight,
  FaFileAlt,
  FaHome,
  FaInfoCircle,
  FaStar,
  FaSuitcase,
  FaUser,
  FaUserCircle,
  FaWallet,
} from 'react-icons/fa';
import PlaceholderGrid from 'rsuite/esm/Placeholder/PlaceholderGrid';
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph';

const { withRouter } = util;

type State = {
  id: string | null;
  details: any;
  tabKey: string;
  staticTabKey: string;
  steps: Array<any>;

  technicalTestPayload: object;
  offerLetterFile: any;
  templateLink: string;

  showModal: boolean;
  showAcceptedModal: boolean;
  showInitiatedModal: boolean;
  showTransferJobConfirmModal: boolean;
  showUploadOfferLetterModal: boolean;

  isTransferConfirmed: boolean;
  isAcceptTransfer: boolean;

  loading: boolean;
};

const { StringType } = Schema.Types;

class CandidateMyApplicationDetailScreen extends React.Component<any, State> {
  formUploaderRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formUploaderSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      id: null,
      details: null,
      tabKey: '',
      staticTabKey: '',
      steps: [],
      technicalTestPayload: {},
      offerLetterFile: null,
      templateLink: '',

      showModal: false,
      showAcceptedModal: false,
      showInitiatedModal: false,
      showTransferJobConfirmModal: false,
      showUploadOfferLetterModal: false,

      isTransferConfirmed: false,
      isAcceptTransfer: false,

      loading: true,
    };

    this.formUploaderRef = React.createRef();
    this.formUploaderSchemaModel = Schema.Model({
      solution: StringType()
        .isRequired('Please fill the required field')
        .isURL(),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    const { params } = this.props;
    const id = _.get(params, 'id');

    this.setState({ id });
    this.getInterviewSteps();
    this.getOfferLetterTemplate();
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const prevId = _.get(prevProps, 'params.id');
    const id = _.get(this.props, 'params.id');

    if (prevId !== id) {
      this.setState({ id, loading: true }, () => {
        this.getApplicationDetails();
      });
    }
  }

  private getOfferLetterTemplate = async (): Promise<void> => {
    const year = moment().format('YYYY');
    const response = await MiscAPI.getTemplates(`OFFERINGLETTER_${year}`);
    const link = _.get(response, 'data.0.url');
    this.setState({
      templateLink: link || '',
    });
  };

  private getInterviewSteps = async (): Promise<void> => {
    try {
      const res = await JobApplicationAPI.interviewSteps();
      const steps = _.get(res, 'data') || [];

      this.setState(
        {
          steps,
          loading: false,
        },
        () => {
          this.getApplicationDetails();
        },
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getApplicationDetails = async (): Promise<void> => {
    try {
      const { id, steps } = this.state;
      const res = await JobApplicationAPI.details(Number(id));

      const details = _.get(res, 'data');
      const lastStatus = _.get(details, 'lastStatus');

      let tabKey = '';
      let staticTabKey = '';
      const isGeneralTest = _.includes(
        [
          'GENERAL_TEST_SENT',
          'GENERAL_TEST_SUBMITTED_BY_CANDIDATE',
          'GENERAL_TEST_EXPIRED',
          'CANDIDATE_WAITING_FOR_GENERAL_TEST',
          'INITIATED',
          'REJECTED_BY_SYSTEM',
          'REJECTED_BY_ADMIN',
        ],
        lastStatus,
      );
      const isInterview = _.includes(
        [
          'INTERVIEW_IN_PROGRESS',
          'INTERVIEW_REJECTED_BY_HIRING_PARTNER',
          'TECHNICAL_TEST_PASSED',
          'REJECTED_BY_REMINDER_SYSTEM',
        ],
        lastStatus,
      );
      const isWaitingTransferJobConfirm =
        lastStatus === 'WAITING_FOR_TRANSFER_JOB_CONFIRMATION';

      if (isGeneralTest) {
        tabKey = String(_.get(steps, '0.name'));
        staticTabKey = tabKey;
      } else if (isInterview) {
        tabKey = String(_.get(steps, '2.name'));
        staticTabKey = tabKey;
      } else if (isWaitingTransferJobConfirm) {
        tabKey = 'Waiting For Transfer Job Confirm';
        staticTabKey = String(_.get(steps, '1.name'));
      } else if (lastStatus === 'INTERVIEW_ACCEPTED') {
        tabKey = 'Accepted';
        staticTabKey = tabKey;
      } else if (lastStatus === 'OFFER_ACCEPTED') {
        tabKey = 'Offer Accepted';
        staticTabKey = tabKey;
      } else {
        tabKey = String(_.get(steps, '1.name'));
        staticTabKey = tabKey;
      }

      this.setState({
        details,
        tabKey,
        staticTabKey,
        showInitiatedModal: lastStatus === 'INITIATED',
        showAcceptedModal: lastStatus === 'INTERVIEW_ACCEPTED',
        loading: false,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  private onTabChanged = (tabKey: string): void => {
    this.setState({ tabKey });
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={6} md={6} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/search-companies')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/search-companies',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaBuilding />}
          >
            Company
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/search-jobs')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/search-jobs')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaSuitcase />}
          >
            Search Job
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/my-application')
            }
            appearance="subtle"
            className={
              String(location.pathname).includes('my-application')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaFileAlt />}
          >
            My Application
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/profile')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/profile')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            Profile
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderGeneralTest = (
    { details, steps, staticTabKey } = this.state,
  ): JSX.Element | null => {
    const isExpired = moment(
      _.get(details, 'dueDate'),
      'DD-MMM-YYYY HH:mm Z',
    ).isBefore(moment());
    const lastStatus = _.get(details, 'lastStatus');
    const storage = AuthStorage.getInstance();
    const user = storage.getUser();
    const formatted = 'DD MMM YYYY';
    const expDate = _.get(user, 'generalTestScoreExpiryDate') || '-';
    const formattedExpDate =
      expDate !== '-'
        ? moment(expDate, 'YYYY-MM-DD HH:mm:ss').format(formatted)
        : '-';

    return (
      <Panel className="myapplication-card">
        <Stack spacing={8}>
          <p className="myapplication-title">{_.get(steps, '0.title')}</p>
        </Stack>
        <p
          dangerouslySetInnerHTML={{ __html: `${_.get(steps, '0.subtitle')}` }}
        />
        <Divider />

        <Stack spacing={8}>
          <p
            dangerouslySetInnerHTML={{
              __html: `${_.get(steps, '0.description')}`,
            }}
          />
        </Stack>

        <br />
        <br />

        <Stack spacing={8}>
          <Row>
            <Col xs={24} sm={16}>
              <p className="myapplication-title">
                {staticTabKey === _.get(steps, '0.name') ? 'Due Date' : 'Notes'}
              </p>
              <p>
                {staticTabKey === _.get(steps, '0.name')
                  ? lastStatus === 'GENERAL_TEST_SENT' &&
                    (_.get(details, 'dueDate') || '-')
                  : `You have passed this test. Validity until ${formattedExpDate}. Kindly proceed to Technical Test.`}
              </p>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                block
                className="btn-tg-primary job-details-header-btn"
                appearance="primary"
                id="myapplication-submit-test-button"
                disabled={
                  !_.isEqual(lastStatus, 'GENERAL_TEST_SENT') || isExpired
                }
                onClick={(): void => {
                  const token = details.invitationToken;

                  window.open(
                    `/dashboard/general-test/${token}/apply`,
                    '_self',
                  );
                }}
              >
                Take Test
              </Button>
            </Col>
          </Row>
        </Stack>
      </Panel>
    );
  };

  renderTechnicalTest = (
    { details, steps, staticTabKey } = this.state,
  ): JSX.Element | null => {
    const lastStatus = _.get(details, 'lastStatus');
    const technicalTestUrl = _.get(details, 'template.0.url');

    return (
      <Panel className="myapplication-card">
        <Stack spacing={8}>
          <p className="myapplication-title">{_.get(steps, '1.title')}</p>
        </Stack>
        <p
          dangerouslySetInnerHTML={{ __html: `${_.get(steps, '1.subtitle')}` }}
        />
        <Divider />

        <Stack spacing={8}>
          <p
            dangerouslySetInnerHTML={{
              __html: `${_.get(steps, '1.description')}`,
            }}
          />
        </Stack>

        <br />
        <br />

        <Stack spacing={8}>
          <Row>
            {technicalTestUrl &&
              !_.isEqual(lastStatus, 'TECHNICAL_TEST_EXPIRED') && (
                <Col xs={24}>
                  <p className="myapplication-title">
                    Technical Case Study test questions link
                  </p>
                  <a
                    href={technicalTestUrl}
                    target="_blank"
                    style={{
                      textDecoration: 'underline',
                      color: 'var(--rs-text-link)',
                    }}
                    rel="noreferrer"
                  >
                    View Test
                  </a>
                  <br />
                  <br />
                </Col>
              )}
            {String(lastStatus).includes('REJECT') && (
              <Col xs={24} sm={16}>
                <p className="myapplication-title">
                  {staticTabKey === _.get(steps, '1.name')
                    ? 'Rejection Reason'
                    : ''}
                </p>
                <p>
                  {staticTabKey === _.get(steps, '1.name') &&
                    (_.get(this.state, 'details.rejectionReason') || '-')}
                </p>
              </Col>
            )}
            <Col xs={24} sm={16}>
              <p className="myapplication-title mt-4">
                {staticTabKey === _.get(steps, '1.name') ? 'Due Date' : 'Notes'}
              </p>
              <p>
                {staticTabKey === _.get(steps, '1.name')
                  ? lastStatus === 'TECHNICAL_TEST_SENT'
                    ? _.get(details, 'dueDate') || '-'
                    : '-'
                  : 'You have passed this test. Kindly proceed to the next step.'}
              </p>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                block
                id="myapplication-submit-test-button"
                className="btn-tg-primary job-details-header-btn"
                appearance="primary"
                disabled={
                  !_.isEqual(lastStatus, 'TECHNICAL_TEST_SENT') ||
                  _.isEqual(lastStatus, 'TECHNICAL_TEST_EXPIRED')
                }
                onClick={() => {
                  this.setState({ showModal: true });
                }}
              >
                {(lastStatus === 'TECHNICAL_TEST_SENT' ||
                  lastStatus === 'TECHNICAL_TEST_EXPIRED' ||
                  lastStatus === 'CANDIDATE_WAITING_FOR_TECHNICAL_TEST') &&
                  'Submit Test'}
                {!(
                  lastStatus === 'TECHNICAL_TEST_EXPIRED' ||
                  lastStatus === 'TECHNICAL_TEST_SENT' ||
                  lastStatus === 'CANDIDATE_WAITING_FOR_TECHNICAL_TEST'
                ) && 'Submitted'}
              </Button>
            </Col>
          </Row>
        </Stack>
      </Panel>
    );
  };

  renderInterviewTest = (
    { steps, staticTabKey, details } = this.state,
  ): JSX.Element | null => (
    <Panel className="myapplication-card">
      <Stack spacing={8}>
        <p className="myapplication-title">{_.get(steps, '2.title')}</p>
      </Stack>
      <p
        dangerouslySetInnerHTML={{ __html: `${_.get(steps, '2.subtitle')}` }}
      />
      <Divider />

      <Stack spacing={8}>
        <p
          dangerouslySetInnerHTML={{
            __html: `${_.get(steps, '2.description')}`,
          }}
        />
      </Stack>

      <br />
      <br />

      <Stack spacing={8}>
        <Row>
          {String(_.get(details, 'lastStatus')).includes('REJECT') && (
            <Col xs={24} sm={16}>
              <p className="myapplication-title">
                {staticTabKey === _.get(steps, '2.name')
                  ? 'Rejection Reason'
                  : ''}
              </p>
              <p>
                {staticTabKey === _.get(steps, '2.name') &&
                  (_.get(this.state, 'details.rejectionReason') || '-')}
              </p>
            </Col>
          )}
          <Col xs={24} sm={16}>
            <p className="myapplication-title mt-4">Duration</p>
            <p>
              {staticTabKey === _.get(steps, '2.name')
                ? `${_.get(steps, '2.durationAmount')} ${_.get(
                    steps,
                    '2.durationUnit',
                  )}`
                : 'Expired'}
            </p>
          </Col>
          {/* <Col xs={24} sm={8}>
              <Button
                block
                className="btn-tg-primary job-details-header-btn"
                appearance="primary"
                disabled={
                  !_.includes(
                    ['TECHNICAL_TEST_PASSED', 'INTERVIEW_IN_PROGRESS'],
                    lastStatus,
                  )
                }
                onClick={() => window.open(link, '_blank')}
              >
                Interview Link
              </Button>
            </Col> */}
        </Row>
      </Stack>
    </Panel>
  );

  renderWaitingForTransferJob = (
    { details } = this.state,
  ): JSX.Element | null => (
    <Panel className="myapplication-card">
      <Stack spacing={8}>
        <p className="myapplication-title">Transfer Confirmation</p>
      </Stack>
      <p>Read the explanations below carefully</p>
      <Divider />

      <Stack spacing={8} direction="column">
        <b>
          Transfer to:{' '}
          {_.get(details, 'transferHistories.0.company.name') || '-'}
        </b>
        <b>
          Role: {_.get(details, 'transferHistories.0.jobPosting.title') || '-'}
        </b>
        <Button
          className="btn-tg-pill-shaped tg-btn-active float-left"
          appearance="primary"
          onClick={() => {
            const id = _.get(details, 'transferHistories.0.jobPosting.id');
            window.open(`/dashboard/candidate/jobs/${id}`);
          }}
        >
          Check the Role
          <FaChevronRight
            style={{
              width: '16px',
              height: '16px',
              marginLeft: '.5em',
              marginTop: '0px',
            }}
          />
        </Button>
        <br />
        <p>
          Greetings! Since you were rejected from the previous application, we
          are offering you an interview opportunity for this vacancy because
          your Technical Case Study is awesome! We feel this is a good fit for
          you and we hope you will accept the role transfer. Please click the
          button below.
        </p>
      </Stack>

      <br />
      <br />

      <Stack spacing={16} direction="column">
        <Row>
          <Col xs={24}>
            <p className="myapplication-title color-red">
              Due Date for Confirmation
            </p>
            <p className="color-red">
              {_.get(details, 'transferHistories.0.dueDate') || '-'}
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={24}>
            <Button
              className="btn-tg-primary"
              appearance="primary"
              style={{
                paddingLeft: '16px',
                paddingRight: '16px',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              onClick={() =>
                this.setState({
                  showTransferJobConfirmModal: true,
                  isAcceptTransfer: true,
                })
              }
            >
              Yes, I Want to Transfer
            </Button>
            <Button
              className="btn-tg-pill-shaped success bg-red"
              appearance="primary"
              style={{ marginRight: '1em' }}
              onClick={() =>
                this.setState({
                  showTransferJobConfirmModal: true,
                  isAcceptTransfer: false,
                })
              }
            >
              No, I Don&apos;t Want
            </Button>
          </Col>
        </Row>
      </Stack>
    </Panel>
  );

  renderInterviewAccepted = (
    { details, templateLink } = this.state,
  ): JSX.Element | null => (
    <Panel className="myapplication-card">
      <Stack spacing={8}>
        <p className="myapplication-title">Offering</p>
      </Stack>
      <p>You got offered!</p>
      <Divider />

      <Stack spacing={8}>
        <p>
          Congratulations after going through several stages,{' '}
          <b>{_.get(details, 'company.name')}</b> decided to accept you as{' '}
          <b>{_.get(details, 'jobPosting.title')}</b> in their company. If you
          are certain that you wish to accept this offer before the deadline,
          please click the button below.
        </p>
      </Stack>

      <br />
      <br />

      <Stack spacing={16} direction="column">
        <Row>
          <Col xs={24}>
            <p className="myapplication-title">Offering Letter</p>
            <a
              href={_.get(details, 'customOfferLetter.url') || templateLink}
              target="_blank"
              className="myapplication-offerletter"
              rel="noreferrer"
            >
              {_.get(details, 'customOfferLetter.filename') || 'Download here'}
            </a>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={16}>
            <p className="myapplication-title">Due Date</p>
            <p>{_.get(details, 'dueDate') || '-'}</p>
          </Col>
          <Col xs={24} sm={8}>
            <Button
              block
              className="btn-tg-pill-shaped success"
              appearance="primary"
              disabled={
                !_.isEqual(_.get(details, 'lastStatus'), 'INTERVIEW_ACCEPTED')
              }
              onClick={() =>
                this.setState({ showUploadOfferLetterModal: true })
              }
            >
              Accept Offer
            </Button>
          </Col>
        </Row>
      </Stack>
    </Panel>
  );

  renderOfferAccepted = ({ details } = this.state): JSX.Element | null => (
    <Panel className="myapplication-card">
      <Stack spacing={8}>
        <p className="myapplication-title">Last Step: Offering</p>
      </Stack>
      <p>You accepted offered!</p>
      <Divider />

      <Stack spacing={8}>
        <p>
          Congratulations on your new role and upcoming journey! Please wait for
          a representative from {_.get(details, 'company.name')} to contact you
          regarding the continuation of your application.
        </p>
      </Stack>

      <br />
      <br />

      <Stack spacing={8}>
        <Row>
          {_.get(details, 'signedOfferLetter') && (
            <Col xs={24}>
              <p className="myapplication-title">Offering Letter</p>
              <a
                href={_.get(details, 'signedOfferLetter.url')}
                target="_blank"
                className="myapplication-offerletter"
                rel="noreferrer"
              >
                {_.get(details, 'signedOfferLetter.filename') ||
                  'Download here'}
              </a>
            </Col>
          )}
          <Col xs={24} sm={16} />
          <Col xs={24} sm={8}>
            <Button
              block
              className="btn-tg-pill-shaped success job-details-header-btn"
              appearance="primary"
              disabled
            >
              Accepted
            </Button>
          </Col>
        </Row>
      </Stack>
    </Panel>
  );

  renderLoadingPanel = (): JSX.Element | null => (
    <Panel className="myapplication-card">
      <Stack spacing={8}>
        <PlaceholderParagraph active />
      </Stack>
      <PlaceholderParagraph active />
      <Divider />

      <Stack spacing={8}>
        <PlaceholderParagraph active />
      </Stack>

      <br />
      <br />

      <Stack spacing={8}>
        <Row>
          <Col xs={24} sm={16}>
            <p className="myapplication-title">Due Date</p>
            <PlaceholderParagraph active />
          </Col>
        </Row>
      </Stack>
    </Panel>
  );

  renderTab = (key: string): Array<JSX.Element> | JSX.Element | null => {
    switch (key) {
      case 'General Test':
        return this.renderGeneralTest();
      case 'Technical Case Study':
        return this.renderTechnicalTest();
      case 'Interview':
        return this.renderInterviewTest();
      case 'Waiting For Transfer Job Confirm':
        return this.renderWaitingForTransferJob();
      case '':
        return this.renderLoadingPanel();
      case 'Offer Accepted':
        return this.renderOfferAccepted();
      default:
        return this.renderInterviewAccepted();
    }
  };

  checkNavItem = (steps: Array<any>, step: any): boolean => {
    const { tabKey, staticTabKey } = this.state;
    if (staticTabKey === 'Offer Accepted') {
      return false;
    }

    if (tabKey === step.name) {
      return false;
    }

    const idx = _.findIndex(steps, { name: step.name });
    const currIdx = _.findIndex(steps, { name: staticTabKey });
    return !(idx <= currIdx);
  };

  private onSubmitClicked = async (): Promise<void> => {
    const { t } = this.props;

    try {
      if (!_.invoke(this.formUploaderRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      // Upload documents
      const payload = _.get(this.state, 'technicalTestPayload');
      const details = _.get(this.state, 'details');
      await JobApplicationAPI.updateStatusToTechnicalTestSubmitted(
        details.id,
        payload,
      );

      toaster.push(
        util.buildMessage('Successfully upload your solution file!'),
      );
      this.setState({ showModal: false });

      this.getApplicationDetails();
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private uploadSignedDocument = async (): Promise<boolean> => {
    try {
      const id = _.get(this.state, 'id');
      const file = _.get(this.state, 'offerLetterFile');
      const blob = {
        blobFile: _.get(file, 'blobFile'),
        name: `signed_letter_file.pdf`,
      };

      if (Number(_.get(blob.blobFile, 'size')) > 2097152) {
        toaster.push(
          util.buildErrorMessage(
            'File size is bigger than maximum file size: 2MB!',
          ),
        );

        return false;
      }

      const base64 = await util.blobToBase64(blob.blobFile);
      await DocumentAPI.upload({
        content: base64,
        table: 'App\\Models\\JobApplication',
        tableId: id,
        filename: blob.name,
      });

      return true;
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
      return false;
    }
  };

  private onAcceptOfferClicked = async (): Promise<void> => {
    try {
      const details = _.get(this.state, 'details');
      const offerLetter = _.get(this.state, 'offerLetterFile');

      if (offerLetter === null) {
        toaster.push(
          util.buildErrorMessage('Please upload the signed document first!'),
        );
        return;
      }

      const isSuccess = await this.uploadSignedDocument();
      if (isSuccess) {
        await JobApplicationAPI.updateStatusToOfferAccepted(details.id);
        toaster.push(util.buildMessage('Successfully accept the offer!'));

        this.setState({ showUploadOfferLetterModal: false });
        this.getApplicationDetails();
      }
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private handleTransferJobButtonClicked = async (
    { isAcceptTransfer } = this.state,
  ): Promise<void> => {
    try {
      const details = _.get(this.state, 'details');
      const status = isAcceptTransfer
        ? 'JOB_TRANSFER_APPROVED'
        : 'JOB_TRANSFER_REJECTED';
      await JobApplicationAPI.updateStatusTo(Number(details.id), status);

      this.setState({
        showTransferJobConfirmModal: false,
        isTransferConfirmed: true,
      });

      this.getApplicationDetails();
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  renderInitiatedModal = (): JSX.Element => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showInitiatedModal')}
      onClose={() => {
        this.setState({ showInitiatedModal: false });
      }}
    >
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-myapplication-initiated.png`}`}
          className="bg-myapplication-initiated"
          alt="bg-img"
        />
        <h3>Please Wait</h3>
        <p style={{ marginTop: '2em' }}>
          We will review your application and please wait for the next actions
          by our admin. You can get back within approximately 1 working day to
          continue to the next step.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => this.setState({ showInitiatedModal: false })}
          appearance="primary"
        >
          I Understand
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderTransferJobModal = (
    { details, showTransferJobConfirmModal, isAcceptTransfer } = this.state,
  ): JSX.Element => (
    <Modal
      backdrop="static"
      open={showTransferJobConfirmModal}
      overflow={false}
      onClose={() => {
        this.setState({ showTransferJobConfirmModal: false });
      }}
    >
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-image-apply-job.png`}`}
          className="bg-apply-job"
          alt="bg-img"
        />
        <h3>
          {isAcceptTransfer
            ? `
              Transfer to ${
                _.get(details, 'transferHistories.0.company.name') || ''
              } as an ${
                _.get(details, 'transferHistories.0.jobPosting.title') || ''
              }?
            `
            : "Are you sure you don't want to transfer?"}
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({ showTransferJobConfirmModal: false });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={() => this.handleTransferJobButtonClicked()}
          appearance="primary"
          className={`${isAcceptTransfer ? '' : 'bg-red'}`}
        >
          {isAcceptTransfer ? 'Yes, Transfer' : "Yes, I Don't Want to "}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderTransferJobConfirmedModal = (
    { isTransferConfirmed } = this.state,
  ): JSX.Element => (
    <Modal backdrop="static" open={isTransferConfirmed} overflow={false}>
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-transfer-job-confirmed.png`}`}
          className="bg-apply-job"
          alt="bg-img"
        />
        <h3>Your transfer has been confirmed.</h3>
        <p style={{ marginTop: '2em' }}>
          The hiring partner is assessing your application. Kindly wait for the
          next step.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => window.location.reload()} appearance="primary">
          Okay, I Understand
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderInterviewAcceptedModal = ({ details } = this.state): JSX.Element => (
    <Modal
      backdrop
      open={_.get(this.state, 'showAcceptedModal')}
      onClose={() => {
        this.setState({ showAcceptedModal: false });
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-myapplication-accepted.png`}`}
          className="bg-myapplication-initiated"
          alt="bg-img"
        />
        <h3>Congratulations, you have been hired!</h3>
        <p style={{ marginTop: '2em' }}>
          <b>{_.get(details, 'company.name')} </b>
          has decided to hire you for{' '}
          <b>{_.get(details, 'jobPosting.title')}</b> after going through
          several phases of selection. If you want to accept an offer, please do
          so before the deadline by clicking the button on the next page. After
          click the button on this page, please wait for a representative from
          the company to contact you.
        </p>
      </Modal.Body>
    </Modal>
  );

  renderUploadTechnicalSolutionModal = (): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showModal')}
      onClose={() => {
        this.setState({ showModal: false });
      }}
    >
      <Modal.Body className="upload-solution-modal-body">
        <b>Upload Technical Case Study</b>
        <p>
          Add your relevant link here. The link can be a Google Drive link that
          contains your work files.
        </p>

        <Form
          key="technical-test-form"
          onChange={(v: object) =>
            this.onFormValueChanged(v, 'technicalTestPayload')
          }
          formValue={_.get(this.state, 'technicalTestPayload')}
          fluid
          model={this.formUploaderSchemaModel}
          ref={this.formUploaderRef}
        >
          <Form.Group>
            <Form.Control
              name="solution"
              // eslint-disable-next-line max-len
              placeholder="Please put your Google Drive link with read permission"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="upload-solution-modal-footer">
        <Button
          onClick={() => {
            this.setState({ showModal: false });
          }}
          className="btn-tg-secondary"
          appearance="default"
        >
          Cancel
        </Button>
        <Button onClick={this.onSubmitClicked} appearance="primary">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderUploadOfferLetterModal = (): JSX.Element | null => (
    <Modal
      backdrop="static"
      overflow={false}
      open={_.get(this.state, 'showUploadOfferLetterModal')}
      onClose={() => {
        this.setState({ showModal: false });
      }}
    >
      <Modal.Body className="upload-solution-modal-body">
        <b>Upload The Signed Offering Letter</b>
        <p>
          Make sure to read the document carefully and sign it before upload it.
          You cannot upload the document again (pdf only) if there is any
          mistake.
        </p>
        <br />

        <Uploader
          multiple={false}
          accept="application/pdf"
          draggable
          action="#"
          maxPreviewFileSize={2097152}
          autoUpload={false}
          onChange={(files) =>
            this.setState({ offerLetterFile: _.get(files, '0') })
          }
        >
          <div
            style={{
              height: 200,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '12px',
            }}
          >
            <span>
              Drag & drop your files here or <a href="#">choose file</a>
              <br />
              <br />
              <small>2 MB max file size (*.PDF, *.doc, *.docx)</small>
            </span>
          </div>
        </Uploader>
      </Modal.Body>
      <Modal.Footer className="upload-solution-modal-footer">
        <Button
          onClick={() => {
            this.setState({ showUploadOfferLetterModal: false });
          }}
          className="btn-tg-secondary"
          appearance="default"
        >
          Cancel
        </Button>
        <Button onClick={this.onAcceptOfferClicked} appearance="primary">
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderStepLogo = (step: any): string => {
    const { steps, details } = this.state;

    const defaultIcon = _.get(step, 'logo.url');
    const isDisabled = this.checkNavItem(steps, step);
    const idx = _.findIndex(steps, { name: step.name });

    if (!isDisabled) {
      const isFailed = _.includes(
        [
          'INTERVIEW_REJECTED_BY_HIRING_PARTNER',
          'REJECTED_BY_SYSTEM',
          'REJECTED_BY_REMINDER_SYSTEM',
          'REJECTED_BY_HIRING_PARTNER',
          'REJECTED_BY_ADMIN',
          'GENERAL_TEST_EXPIRED',
          'TECHNICAL_TEST_EXPIRED',
          'JOB_TRANSFER_REJECTED',
        ],
        details.lastStatus,
      );

      const isSuccess = _.includes(
        [
          'CANDIDATE_WAITING_FOR_TECHNICAL_TEST',
          'TECHNICAL_TEST_SENT',
          'TECHNICAL_TEST_SUBMITTED_BY_CANDIDATE',
          'TECHNICAL_TEST_PASSED',
          'INTERVIEW_IN_PROGRESS',
          'INTERVIEW_ACCEPTED',
          'OFFER_ACCEPTED',
        ],
        details.lastStatus,
      );

      if (isFailed) {
        // Step one
        if (idx === 0) {
          return `${process.env.PUBLIC_URL}/step-one-interview-failed.png`;
        }

        // Step two
        if (idx === 1) {
          return `${process.env.PUBLIC_URL}/step-two-interview-failed.png`;
        }

        // Step three
        if (idx === 2) {
          return `${process.env.PUBLIC_URL}/step-three-interview-failed.png`;
        }
        return `${process.env.PUBLIC_URL}/step-four-interview-failed.png`;
      }

      if (isSuccess) {
        if (idx === 0) {
          return `${process.env.PUBLIC_URL}/step-one-interview-accepted.png`;
        }
        if (
          idx === 1 &&
          _.includes(
            [
              'TECHNICAL_TEST_PASSED',
              'INTERVIEW_IN_PROGRESS',
              'INTERVIEW_ACCEPTED',
              'OFFER_ACCEPTED',
            ],
            details.lastStatus,
          )
        ) {
          return `${process.env.PUBLIC_URL}/step-two-interview-accepted.png`;
        }
        if (
          idx === 2 &&
          _.includes(
            ['INTERVIEW_ACCEPTED', 'OFFER_ACCEPTED'],
            details.lastStatus,
          )
        ) {
          return `${process.env.PUBLIC_URL}/step-three-interview-accepted.png`;
        }
      }

      return defaultIcon;
    }

    if (idx === 1) {
      return `${process.env.PUBLIC_URL}/step-two-interview-disabled.png`;
    }
    if (idx === 2) {
      return `${process.env.PUBLIC_URL}/step-three-interview-disabled.png`;
    }
    return defaultIcon;
  };

  renderMainContent = (): JSX.Element | null => {
    const { loading, details, tabKey, steps } = this.state;
    const workingTypes = _.get(details, 'company.workingTypes') || [];
    const benefits = _.get(details, 'company.benefits') || [];
    const cultures = _.get(details, 'companycultures') || [];
    const companyDescription = _.get(details, 'company.description');
    const companyName = _.get(details, 'company.name');
    const companyLogo = _.get(details, 'company.logo.url');
    const companyIndustry = _.get(details, 'company.industry.name', '-');
    const salaryHigh: number = _.get(details, 'jobPosting.maxSalaryRange') || 0;
    const salaryLow: number = _.get(details, 'jobPosting.minSalaryRange') || 0;
    const companyLocation = `${_.get(
      details,
      'jobPosting.jobLocation.cityName',
      '-',
    )}, ${_.get(details, 'jobPosting.jobLocation.region', '-')}`;
    // const transferHistories: Array<any> = _.reverse(
    //   _.get(details, 'transferHistories') || [],
    // );
    const transferHistories: Array<any> =
      _.get(details, 'transferHistories') || [];

    return (
      <Col
        md={24}
        sm={24}
        xs={24}
        lg={18}
        className="myapplication-main-content"
      >
        <Row>
          <Col xs={24}>
            <a href="/dashboard/candidate/my-application">
              <FaChevronLeft />
              <span>Other Application</span>
            </a>
            <Divider style={{ backgroundColor: 'transparent' }} />
          </Col>

          <Col xs={24} sm={17}>
            <Nav
              activeKey={tabKey}
              onSelect={this.onTabChanged}
              style={{ marginBottom: 50 }}
            >
              {_.map(steps, (step: any, index: number) => (
                <React.Fragment key={step.name}>
                  <Nav.Item
                    eventKey={step.name}
                    disabled={this.checkNavItem(steps, step)}
                  >
                    <img
                      width={36}
                      height={36}
                      src={this.renderStepLogo(step)}
                      alt="logo-step"
                    />
                    {step.name}
                    <small>
                      {_.get(step, 'durationAmount')}{' '}
                      {_.get(step, 'durationUnit')}
                    </small>
                  </Nav.Item>
                  {index !== steps.length - 1 && (
                    <Divider
                      vertical
                      style={{
                        marginTop: '7.5%',
                        height: '2px',
                        width: '0.75em',
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
              <Divider
                vertical
                style={{ marginTop: '7.5%', height: '2px', width: '0.75em' }}
              />
              <Nav.Item
                style={{ width: '15%' }}
                eventKey="interview-complete"
                disabled
              >
                <img
                  width={90}
                  height={60}
                  src={`${process.env.PUBLIC_URL}/img-hired.png`}
                  alt="interview-complete"
                />
              </Nav.Item>
            </Nav>
            {this.renderTab(tabKey)}

            <br />

            <Panel className="myapplication-card">
              <Stack spacing={8}>
                <Row>
                  <Col xs={4}>
                    <Avatar
                      circle
                      size={window.innerWidth > 768 ? 'lg' : 'md'}
                      src={companyLogo}
                      alt="logo-startup"
                    />
                  </Col>
                  <Col xs={20} className="company-info">
                    <b>{companyName}</b>
                    <p>{companyIndustry}</p>
                  </Col>
                </Row>
              </Stack>
              <br />
              <Stack spacing={8}>
                <p className="myapplication-title">About The Company</p>
              </Stack>
              <br />
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderGrid rows={7} columns={1} active />
                </Stack>
              )}
              {!loading && (
                <Stack spacing={8}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: companyDescription,
                    }}
                  />
                </Stack>
              )}
              <br />
              <br />
              <Stack spacing={8}>
                <p className="myapplication-title">Location</p>
              </Stack>
              <br />
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderGrid rows={1} columns={1} active />
                </Stack>
              )}
              {!loading && (
                <Stack spacing={8}>
                  <p>{companyLocation === '-, -' ? '-' : companyLocation}</p>
                </Stack>
              )}
              <br />
              <br />
              <Stack spacing={8}>
                <p className="myapplication-title">Industry</p>
              </Stack>
              <br />
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderGrid rows={1} columns={1} active />
                </Stack>
              )}
              {!loading && (
                <Stack spacing={8}>
                  <p>{companyIndustry}</p>
                </Stack>
              )}
            </Panel>
          </Col>

          <Col xs={24} sm={7}>
            <Panel
              className="myapplication-side-card"
              style={{
                marginBottom: '1em',
                display: transferHistories.length === 0 ? 'none' : 'block',
              }}
            >
              <p
                className="myapplication-title"
                style={{ marginBottom: '1em' }}
              >
                Job Transfer Histories
              </p>
              <Timeline>
                {transferHistories.map((h) => {
                  const views = (
                    <Timeline.Item>
                      <span style={{ fontSize: '12px' }}>
                        {_.get(h, 'createdAt')}
                      </span>
                      - Waiting candidate confirmation for job transfer from{' '}
                      <b>
                        {_.get(h, 'previousCompany.name')} as{' '}
                        {_.get(h, 'previousJobPosting.title')}
                      </b>{' '}
                      to{' '}
                      <b>
                        {_.get(h, 'company.name')} as{' '}
                        {_.get(h, 'jobPosting.title')}
                      </b>
                    </Timeline.Item>
                  );

                  switch (h.status) {
                    case 'REJECTED': {
                      return (
                        <>
                          <Timeline.Item style={{ color: 'red' }}>
                            <span style={{ fontSize: '12px' }}>
                              {_.get(h, 'updatedAt')}
                            </span>
                            - Transfer Job expired/rejected by Candidate
                          </Timeline.Item>
                          {views}
                        </>
                      );
                    }

                    case 'WAITING_CONFIRMATION':
                      return views;

                    default:
                      return (
                        <>
                          <Timeline.Item>
                            <span style={{ fontSize: '12px' }}>
                              {_.get(h, 'updatedAt')}
                            </span>
                            - Transfer Job approved by Candidate
                          </Timeline.Item>
                          {views}
                        </>
                      );
                  }
                })}
              </Timeline>
            </Panel>

            <Panel className="myapplication-side-card">
              <Stack
                spacing={8}
                direction="column"
                style={{ alignItems: 'flex-start' }}
              >
                <Row>
                  <Col xs={3}>
                    <FaUserCircle />
                  </Col>
                  <Col xs={21}>
                    <p className="myapplication-attribute-title">Job Role</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && <p>{_.get(details, 'jobPosting.title')}</p>}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaBuilding />
                  </Col>
                  <Col xs={21}>
                    <p className="myapplication-attribute-title">
                      Working Type
                    </p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {' '}
                        {workingTypes.length > 1 &&
                          _.reduce(
                            workingTypes,
                            (w: string, val: any) => {
                              if (_.isEmpty(w)) {
                                return `${val.name} `;
                              }

                              return `${w}, ${val.name}`;
                            },
                            '',
                          )}
                        {workingTypes.length === 1 &&
                          _.get(workingTypes, '0.name')}
                        {workingTypes.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaWallet />
                  </Col>
                  <Col xs={21}>
                    <p className="myapplication-attribute-title">Salary</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p className="salary-range">
                        {util.formatSalaryRange(salaryLow, salaryHigh)}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaStar />
                  </Col>
                  <Col xs={21}>
                    <p className="myapplication-attribute-title">
                      Other Benefits
                    </p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {benefits.length > 1 &&
                          _.reduce(
                            benefits,
                            (b: string, val: any) => {
                              if (_.isEmpty(b)) {
                                return `${val.name} `;
                              }

                              return `${b}, ${val.name}`;
                            },
                            '',
                          )}

                        {benefits.length === 1 && _.get(benefits, '0.name')}
                        {benefits.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaInfoCircle />
                  </Col>
                  <Col xs={21}>
                    <p className="myapplication-attribute-title">Culture</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {cultures.length > 1 &&
                          _.reduce(
                            cultures,
                            (b: string, val: any) => {
                              if (_.isEmpty(b)) {
                                return `${val.name} `;
                              }

                              return `${b}, ${val.name}`;
                            },
                            '',
                          )}

                        {cultures.length === 1 && _.get(cultures, '0.name')}
                        {cultures.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
              </Stack>
            </Panel>
          </Col>
        </Row>
      </Col>
    );
  };

  render(): JSX.Element | null {
    return (
      <>
        {this.renderTransferJobConfirmedModal()}
        {this.renderTransferJobModal()}
        {this.renderInitiatedModal()}
        {this.renderInterviewAcceptedModal()}
        {this.renderUploadOfferLetterModal()}
        {this.renderUploadTechnicalSolutionModal()}
        <Grid className="home-static-authorized-container" fluid>
          <Row>
            {this.renderSideTabs()}
            {this.renderMainContent()}
          </Row>
        </Grid>
      </>
    );
  }
}

export default withRouter(
  withTranslation()(CandidateMyApplicationDetailScreen),
);
