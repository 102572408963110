import AuthStorage from '@common/services/authStorage';
import { constant, util } from '@helper';
import { FileDownload } from '@rsuite/icons';
import { CompanyAPI, DocumentAPI, MiscAPI } from '@services/api';
import { Promise } from 'bluebird';
import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  FaBuilding,
  FaClock,
  FaFile,
  FaHome,
  FaMapMarkerAlt,
  FaPen,
  FaPlus,
  FaUser,
} from 'react-icons/fa';
import ReactQuill from 'react-quill';
import {
  Col,
  Affix,
  Stack,
  IconButton,
  Grid,
  Row,
  Avatar,
  Badge,
  Panel,
  Tooltip,
  Whisper,
  toaster,
  Divider,
  Modal,
  Button,
  Form,
  FormInstance,
  Schema,
  InputPicker,
  Checkbox,
  CheckboxGroup,
  Uploader,
  Input,
  SelectPicker,
  Loader,
  Radio,
  RadioGroup,
  RangeSlider,
} from 'rsuite';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';
import PlaceholderGrid from 'rsuite/esm/Placeholder/PlaceholderGrid';
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph';

const { withRouter } = util;
const { StringType, ArrayType, NumberType } = Schema.Types;

type State = {
  loading: boolean;
  loadingHeader: boolean;
  loadingInsight: boolean;
  jobPostings: Array<any>;
  details: any;

  showEditCompanyDetailsModal: boolean;
  showEditInsightsModal: boolean;
  showEditAboutCompanyModal: boolean;
  showAddJobPostingModal: boolean;
  showEditHeaderModal: boolean;

  // payloads
  aboutCompany: any;
  companyDetailsPayload: any;
  companyInsightsPayload: any;
  companyHeaderPayload: any;
  addPostingPayload: any;

  // misc
  companyIndustries: Array<any>;
  locations: Array<any>;
  benefits: Array<any>;
  workingTypes: Array<any>;
  roleCategories: Array<any>;
  cultures: Array<any>;
  employmentStatuses: Array<any>;
};

const Textarea = React.forwardRef((props, ref: any) => (
  <Input {...props} as="textarea" ref={ref} />
));

class HiringPartnerMyCompanyScreen extends React.Component<any, State> {
  quillRef: any;

  responsibilityRef: any;

  requirementRef: any;

  formAddPostingSchemaModal: any;

  formAddPostingRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formCompanyDetailsSchemaModel: any;

  formCompanyDetailsRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formCompanyInsightsSchemaModel: any;

  formCompanyInsightsRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      loadingHeader: true,
      loadingInsight: false,
      jobPostings: [],
      details: null,

      showEditCompanyDetailsModal: false,
      showEditInsightsModal: false,
      showEditAboutCompanyModal: false,
      showAddJobPostingModal: false,
      showEditHeaderModal: false,

      companyDetailsPayload: {},
      companyInsightsPayload: {},
      companyHeaderPayload: {},
      addPostingPayload: {},
      aboutCompany: {},

      companyIndustries: [],
      locations: [],
      benefits: [],
      workingTypes: [],
      roleCategories: [],
      cultures: [],
      employmentStatuses: [],
    };

    this.quillRef = React.createRef();
    this.requirementRef = React.createRef();
    this.responsibilityRef = React.createRef();
    this.formAddPostingRef = React.createRef();
    this.formCompanyDetailsRef = React.createRef();
    this.formCompanyInsightsRef = React.createRef();

    this.formCompanyDetailsSchemaModel = Schema.Model({
      name: StringType().isRequired('Please fill the required field'),
      region: StringType().isRequired('Please fill the required field'),
      address: StringType().isRequired('Please fill the required field'),
      industryId: NumberType().isRequired('Please fill the required field'),
      website: StringType()
        .isURL()
        .isRequired('Please fill the required field'),
      employeeMinSize: StringType().isRequired(
        'Please fill the required field',
      ),
    });

    this.formAddPostingSchemaModal = Schema.Model({
      title: StringType().isRequired('Please fill the required field'),
      duration: NumberType()
        .min(0)
        .isRequired('Please fill the required field'),
      technicalTest: StringType().isURL(),
      locationId: NumberType().isRequired('Please fill the required field'),
      empStatus: NumberType().isRequired('Please fill the required field'),
      workingType: NumberType().isRequired('Please fill the required field'),
      roleCategoryId: NumberType().isRequired('Please fill the required field'),
      minYoe: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.maxYoe) {
            return Number(value) < Number(data.maxYoe);
          }

          return true;
        }, 'Year start should be less than year end'),
      maxYoe: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.minYoe) {
            return Number(value) > Number(data.minYoe);
          }

          return true;
        }, 'Year end should be greater than year start'),
      salaryRange: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(2),
    });

    this.formCompanyInsightsSchemaModel = Schema.Model({
      benefits: ArrayType().isRequired('Please fill the required field'),
      workingTypes: ArrayType().isRequired('Please fill the required field'),
      cultures: ArrayType().isRequired('Please fill the required field'),
    });
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    this.getCompanyDetails();
  }

  private getLocations = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      this.setState({ locations: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getRoleCategories = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('ROLE_CATEGORY');
    const data = _.get(response, 'data') || [];
    this.setState({
      roleCategories: data,
    });
  };

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getChildAttributes('COMPANY_INDUSTRY');
    const response2 = await MiscAPI.getAttributes('COMPANY_INDUSTRY');

    const data = _.get(response, 'data') || [];
    const data2 = _.filter(
      _.get(response2, 'data') || [],
      (o: any) => o.numberOfChildren === 0,
    );

    this.setState({
      companyIndustries: _.sortBy([...data, ...data2], 'name'),
    });
  };

  private getCompanyBenefits = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('BENEFIT');
    const data = _.get(response, 'data') || [];
    this.setState({
      benefits: data,
      loadingInsight: false,
    });
  };

  private getCompanyCultures = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('CULTURE');
    const data = _.get(response, 'data') || [];
    this.setState({
      cultures: data,
      loadingInsight: false,
    });
  };

  private getCompanyWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
      loadingInsight: false,
    });
  };

  private getCompanyDetails = async (): Promise<void> => {
    try {
      const storage = AuthStorage.getInstance();
      const user = storage.getUser();
      const companyId = _.get(user, 'companyId');
      const response = await CompanyAPI.getCompanyDetails(Number(companyId));
      const data: any = _.get(response, 'data');
      const jobPostings = _.get(data, 'jobs') || [];

      _.set(data, 'email', _.get(user, 'email'));

      this.setState({
        jobPostings,
        details: data,
        loading: false,

        aboutCompany: {
          description: _.get(data, 'description'),
        },
        companyDetailsPayload: {
          name: _.get(data, 'name'),
          region: _.get(data, 'region'),
          industryId: _.get(data, 'industry.id'),
          address: _.get(data, 'address'),
          website: _.get(data, 'website'),
          employeeMinSize: `${_.get(data, 'employeeMinSize')}-${_.get(
            data,
            'employeeMaxSize',
          )}`,
        },
        companyInsightsPayload: {
          benefits: _.flatMap(_.get(data, 'benefits') || [], 'id'),
          workingTypes: _.flatMap(_.get(data, 'workingTypes') || [], 'id'),
          cultures: _.flatMap(_.get(data, 'cultures') || [], 'id'),
        },
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private closeEditCompanyDetailsModal = (
    e: any,
    { details } = this.state,
  ): void => {
    this.setState({
      showEditCompanyDetailsModal: false,
      companyDetailsPayload: {
        name: _.get(details, 'name'),
        region: _.get(details, 'region'),
        industryId: _.get(details, 'industry.id'),
        address: _.get(details, 'address'),
        website: _.get(details, 'website'),
        employeeMinSize: `${_.get(details, 'employeeMinSize')}-${_.get(
          details,
          'employeeMaxSize',
        )}`,
      },
    });
  };

  private closeEditInsightsModal = (e: any, { details } = this.state): void => {
    this.setState({
      showEditInsightsModal: false,
      companyInsightsPayload: {
        benefits: _.flatMap(_.get(details, 'benefits') || [], 'id'),
        workingTypes: _.flatMap(_.get(details, 'workingTypes') || [], 'id'),
        cultures: _.flatMap(_.get(details, 'cultures') || [], 'id'),
      },
    });
  };

  private getEmploymentStatus = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('EMPLOYMENT_STATUS');
    const data = _.get(response, 'data') || [];
    this.setState({
      employmentStatuses: data,
    });
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  private showEditAboutCompanyModal = (): void => {
    this.setState({ showEditAboutCompanyModal: true });
  };

  private showAddJobPostingModal = (): void => {
    this.setState({ showAddJobPostingModal: true });

    this.getCompanyWorkingTypes();
    this.getEmploymentStatus();
    this.getRoleCategories();
  };

  private showEditInsightsModal = async (): Promise<void> => {
    this.setState({ showEditInsightsModal: true, loadingInsight: true });

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getCompanyBenefits();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyCultures();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyWorkingTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  };

  private showEditCompanyDetailsModal = async (): Promise<void> => {
    this.setState({ showEditCompanyDetailsModal: true });

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getLocations();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  };

  private updateCompanyDetails = async (formName: string): Promise<void> => {
    try {
      const { t } = this.props;
      const f = _.get(this, formName);

      if (formName === 'quillRef') {
        if (this.quillRef.getLength() <= 1) {
          throw new Error(
            t(constant.translation.basicInfo.formErrorValidationMessageKey),
          );
        }
      } else if (!_.invoke(f, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      // Construct payload
      const aboutText = _.get(this.quillRef, 'container.firstChild.innerHTML');
      const { details } = this.state;
      const detailsPayload = _.get(this.state, 'companyDetailsPayload');
      const insightsPayload = _.get(this.state, 'companyInsightsPayload');
      const payload = {
        email: _.get(details, 'email'),
        status: 'VERIFIED',
        ...detailsPayload,
      };

      if (!_.isUndefined(aboutText)) {
        _.set(payload, 'description', aboutText);
      } else {
        _.set(payload, 'description', details.description);
      }

      if (_.get(details, 'id')) {
        await CompanyAPI.updateInfo(details.id, payload, insightsPayload);
      }

      const logo = _.get(detailsPayload, 'companyLogo.0');
      if (logo) {
        const base64 = await util.blobToBase64(logo.blobFile);
        await DocumentAPI.upload({
          content: base64,
          table: 'App\\Models\\Company',
          tableId: _.get(details, 'id'),
          filename: 'company.png',
        });
      }

      this.setState({
        showEditAboutCompanyModal: false,
        showEditInsightsModal: false,
        showEditCompanyDetailsModal: false,
      });

      this.getCompanyDetails();

      toaster.push(
        util.buildMessage('Successfully update your company information!'),
      );
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  closeAddJobPostingModal = (): void => {
    this.setState({
      showAddJobPostingModal: false,
      addPostingPayload: {},
    });
  };

  updateCompanyHeader = async (): Promise<void> => {
    try {
      const { companyHeaderPayload, details } = this.state;
      const header = _.get(companyHeaderPayload, 'companyHeader.0');
      if (header) {
        const base64 = await util.blobToBase64(header.blobFile);
        this.setState({ showEditHeaderModal: false });
        await DocumentAPI.upload({
          content: base64,
          table: 'App\\Models\\Company',
          tableId: _.get(details, 'id'),
          filename: 'bg-header.png',
        });
        toaster.push(
          util.buildMessage(
            'Company header background has been successfully updated',
          ),
        );

        this.setState({ loadingHeader: true });
        this.getCompanyDetails();
      }
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  submitJobPosting = async (): Promise<void> => {
    const { t } = this.props;

    try {
      if (!_.invoke(this.formAddPostingRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      // Check description field, is it empty?
      if (this.responsibilityRef.getLength() <= 1) {
        throw new Error('Please fill the job description field!');
      }

      // Check requirement field, is it empty?
      if (this.requirementRef.getLength() <= 1) {
        throw new Error('Please fill the job requirements field!');
      }

      const storage = AuthStorage.getInstance();
      const user = storage.getUser();
      const { addPostingPayload } = this.state;
      const { workingType, salaryRange } = addPostingPayload;
      const technicalTestUrl = _.get(addPostingPayload, 'technicalTest');

      let technicalTestId = null;

      if (technicalTestUrl) {
        const companyName = _.get(user, 'company.name');
        const jobTitle = _.get(addPostingPayload, 'title');
        const name = `${companyName} | ${jobTitle}`;
        const resp = await MiscAPI.addDataTemplate({
          url: technicalTestUrl,
          name,
        });
        technicalTestId = _.get(resp, 'data.id');
      }

      const payload = {
        ...addPostingPayload,
        technicalTestId,
        companyId: _.get(user, 'companyId'),
        description: _.get(
          this.responsibilityRef,
          'container.firstChild.innerHTML',
        ),
        requirements: _.get(
          this.requirementRef,
          'container.firstChild.innerHTML',
        ),
        minSalaryRange: _.get(salaryRange, '0'),
        maxSalaryRange: _.get(salaryRange, '1'),
        hot: 1,
        active: 1,
      };

      _.unset(payload, 'workingType');
      _.unset(payload, 'salaryRange');
      _.unset(payload, 'technicalTest');

      await CompanyAPI.addPosting(payload, { workingType });

      this.setState({
        showAddJobPostingModal: false,
        addPostingPayload: {},
      });

      this.getCompanyDetails();

      toaster.push(util.buildMessage('Successfully added new job posting!'));
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/hiring-partner/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/hiring-partner/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/hiring-partner/mycompany')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/hiring-partner/mycompany',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            My Company
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderJobOpenings = ({ loading } = this.state): JSX.Element => (
    <Panel className="mycompany-details-card company-job-opening-card">
      <br />
      {!loading && (
        <Stack spacing={8}>
          <Row>
            <Col xs={18}>
              <h3
                className="mycompany-details-title"
                style={{
                  marginTop: '8px',
                }}
              >
                Your Job Openings Right Now!
              </h3>
            </Col>
            <Col xs={6}>
              <Button
                className="btn-tg-primary add-new-job"
                style={{ float: 'right' }}
                appearance="primary"
                onClick={this.showAddJobPostingModal}
              >
                <FaPlus /> &nbsp; {window.innerWidth > 425 ? 'Add New Job' : ''}
              </Button>
            </Col>
          </Row>
        </Stack>
      )}
      {loading && (
        <Stack spacing={8} className="loading-skeleton">
          <PlaceholderParagraph active />
        </Stack>
      )}

      <br />
      <Row>
        {_.map(
          _.get(this.state, 'jobPostings') || [],
          (obj: any, idx: number) => {
            const employmentStatus = _.get(obj, 'employmentStatus.0.name');
            const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
            const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
            const duration = _.get(obj, 'duration');
            const isHot = _.get(obj, 'hot');
            const wType = _.get(obj, 'workingTypes.0.name');
            const { navigate } = this.props;
            const id = _.get(obj, 'id');

            return (
              <Col
                key={`col-searchresults-${idx}`}
                xs={24}
                sm={12}
                md={12}
                lg={8}
              >
                <Panel
                  className="mycompany-details-search-result-card"
                  id="job-openings"
                  onClick={() =>
                    navigate(`/dashboard/hiring-partner/mycompany/job/${id}`)
                  }
                >
                  <Stack
                    direction="column"
                    spacing={20}
                    className="badge-stack"
                    style={{ alignItems: 'flex-start' }}
                  >
                    {/* Badge */}
                    <Stack spacing={8}>
                      {isHot ? <Badge content="New" /> : <span />}
                      <Badge
                        className={
                          employmentStatus === 'Full Time'
                            ? 'rs-badge-fulltime'
                            : ''
                        }
                        content={employmentStatus}
                      />
                      {wType && (
                        <Badge key={`workingTypes-${wType}`} content={wType} />
                      )}
                    </Stack>

                    {/* Company Information */}
                    <Stack spacing={6}>
                      <Row>
                        <Col xs={6} md={8}>
                          <Avatar
                            circle
                            size="lg"
                            src={_.get(this.state, 'details.logo.url')}
                            alt="logo-startup"
                          />
                        </Col>
                        <Col xs={18} md={16} className="company-info">
                          <Whisper
                            placement="topStart"
                            speaker={
                              <Tooltip>
                                <b>{_.get(this.state, 'details.name')}</b>
                              </Tooltip>
                            }
                          >
                            <p>{_.get(this.state, 'details.name')}</p>
                          </Whisper>
                          <Whisper
                            placement="topStart"
                            speaker={
                              <Tooltip>
                                <b>{_.get(obj, 'title')}</b>
                              </Tooltip>
                            }
                          >
                            <b>{_.get(obj, 'title')}</b>
                          </Whisper>
                        </Col>
                      </Row>
                    </Stack>

                    {/* Job Details */}
                    <Stack direction="column" spacing={8}>
                      <Row>
                        <Col xs={3}>
                          <FaMapMarkerAlt />
                        </Col>
                        <Col xs={21}>
                          <p>{_.get(this.state, 'details.region')}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                          <FaClock />
                        </Col>
                        <Col xs={21}>
                          {duration > 0 && <p>{duration} month(s)</p>}
                          {duration === 0 && <p>Full Time</p>}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                          <FaBuilding />
                        </Col>
                        <Col xs={21}>
                          <p>{_.get(this.state, 'details.industry.name')}</p>
                        </Col>
                      </Row>
                    </Stack>

                    {/* Salary range */}
                    <Stack spacing={6}>
                      <p className="salary-range">
                        {util.formatSalaryRange(salaryLow, salaryHigh)}
                      </p>
                    </Stack>
                  </Stack>
                </Panel>
              </Col>
            );
          },
        )}

        {!loading && _.get(this.state, 'jobPostings').length === 0 && (
          <>
            <br />
            <p className="no-results-text">No results</p>
          </>
        )}
      </Row>
    </Panel>
  );

  renderMyOverview = (
    { loading, loadingHeader, details } = this.state,
  ): JSX.Element => (
    <>
      <Col xs={24}>
        <img
          src={_.get(
            details,
            'background.url',
            util.mapBgUrl(_.get(details, 'industry.name') || ''),
          )}
          alt="bg-mycompany"
          style={{ display: loadingHeader ? 'none' : 'block' }}
          onLoad={() => this.setState({ loadingHeader: false })}
        />
        {loadingHeader && <PlaceholderGraph active />}
        {!loadingHeader && (
          <Button
            className="btn-tg-primary btn-tg-edit-header"
            style={{ float: 'right' }}
            appearance="primary"
            onClick={() => this.setState({ showEditHeaderModal: true })}
          >
            &nbsp; {window.innerWidth > 425 ? 'Edit Image' : ''}
            <img src="/icon-edit-white.png" alt="edit-logo" />
          </Button>
        )}
      </Col>
      <Col xs={24} md={24} lg={7}>
        <Panel className="mycompany-details-side-card">
          <Stack spacing={8}>
            <p className="mycompany-details-title">Company Detail</p>
            <img
              src="/icon-edit.png"
              alt="edit-logo"
              onClick={this.showEditCompanyDetailsModal}
            />
          </Stack>
          <Divider />

          <Col xs={24} className="company-icon">
            <img
              src={
                _.get(details, 'logo.url') || 'https://via.placeholder.com/150'
              }
              alt="company-logo"
            />
            <p className="company-name">{_.get(details, 'name')}</p>
          </Col>

          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Location</p>
          </Stack>
          {!loading && (
            <Stack spacing={8}>
              <p>{_.get(details, 'region')}</p>
            </Stack>
          )}
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={2} columns={1} active />
            </Stack>
          )}

          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Industry</p>
          </Stack>
          {!loading && (
            <Stack spacing={8}>
              <p>{_.get(details, 'industry.name')}</p>
            </Stack>
          )}
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={2} columns={1} active />
            </Stack>
          )}

          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Office Address</p>
          </Stack>
          {!loading && (
            <Stack spacing={8}>
              <p>{_.get(details, 'address')}</p>
            </Stack>
          )}
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={2} columns={1} active />
            </Stack>
          )}

          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Company Size</p>
          </Stack>
          {!loading && (
            <Stack spacing={8}>
              {_.get(details, 'employeeMinSize') > 10000 && <p>{'>'}10000</p>}
              {_.get(details, 'employeeMinSize') <= 10000 && (
                <p>
                  {_.get(details, 'employeeMinSize')} -{' '}
                  {_.get(details, 'employeeMaxSize')}
                </p>
              )}
            </Stack>
          )}
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={2} columns={1} active />
            </Stack>
          )}

          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Website</p>
          </Stack>
          {!loading && (
            <Stack spacing={8}>
              <a
                href={util.toLink(_.get(details, 'website'))}
                target="_blank"
                rel="noreferrer"
              >
                {util.toLink(_.get(details, 'website'))}
              </a>
            </Stack>
          )}
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={2} columns={1} active />
            </Stack>
          )}

          <br />
        </Panel>
      </Col>
      <Col xs={24} md={24} lg={17}>
        <Panel className="mycompany-details-card">
          <Stack spacing={8}>
            <Stack.Item>
              <p className="mycompany-details-title">About the Company</p>
            </Stack.Item>
            <Stack.Item>
              <img
                src="/icon-edit.png"
                alt="edit-logo"
                onClick={this.showEditAboutCompanyModal}
              />
            </Stack.Item>
          </Stack>
          <Divider />
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={7} columns={1} active />
            </Stack>
          )}
          {!loading && (
            <Stack spacing={8}>
              <p
                dangerouslySetInnerHTML={{
                  __html: _.get(details, 'description'),
                }}
              />
            </Stack>
          )}

          <br />
        </Panel>

        <br />

        <Panel className="mycompany-details-card">
          <Stack spacing={8}>
            <Stack.Item>
              <p className="mycompany-details-title">Our Culture</p>
            </Stack.Item>
            <Stack.Item>
              <img
                src="/icon-edit.png"
                alt="edit-logo"
                onClick={this.showEditInsightsModal}
              />
            </Stack.Item>
          </Stack>
          <Divider />
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={5} columns={1} active />
            </Stack>
          )}
          <Row>
            {_.map(_.get(details, 'cultures') || [], (c: any) => (
              <Col
                key={c.name}
                xs={24}
                sm={12}
                md={12}
                lg={8}
                className="cultures-icon"
              >
                <img
                  src={
                    _.get(c, 'logo.url') || 'https://via.placeholder.com/150'
                  }
                  alt="company-culture-logo"
                />
                <b>{c.name}</b>
                <p className="culture-text">{_.get(c, 'description') || '-'}</p>
              </Col>
            ))}
          </Row>

          <br />
          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Benefit</p>
          </Stack>
          <Divider />
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={7} columns={1} active />
            </Stack>
          )}

          {(_.get(details, 'benefits') || []).map((b: any) => (
            <li>{b.name}</li>
          ))}

          <br />
          <br />

          <Stack spacing={8}>
            <p className="mycompany-details-title">Working Type</p>
          </Stack>
          <Divider />
          {loading && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={5} columns={1} active />
            </Stack>
          )}

          {(_.get(details, 'workingTypes') || []).map((wt: any) => (
            <li>{wt.name}</li>
          ))}

          <br />
        </Panel>
      </Col>
    </>
  );

  renderMainContent = (): JSX.Element | null => (
    <Col
      md={24}
      sm={24}
      xs={24}
      lg={19}
      className="mycompany-hiring-partner-main-content"
    >
      <Col xs={24}>{this.renderMyOverview()}</Col>

      <Col xs={24}>{this.renderJobOpenings()}</Col>
    </Col>
  );

  renderEditHeaderModal = (
    { t } = this.props,
    { details } = this.state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showEditHeaderModal')}
      onClose={() => {
        this.setState({
          showEditHeaderModal: false,
        });
      }}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          key="company-header-form"
          onChange={(v: object) =>
            this.onFormValueChanged(v, 'companyHeaderPayload')
          }
          formValue={_.get(this.state, 'companyHeaderPayload')}
          fluid
        >
          {/* Company Header */}
          <Row>
            <Col xs={8}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constant.translation.general.formCompanyHeaderKey)}
                </Form.ControlLabel>
              </Form.Group>
            </Col>
            <Col xs={16}>
              <Form.Control
                name="companyHeader"
                accepter={Uploader}
                fileListVisible={false}
                listType="picture"
                action="#"
                accept="image/png"
                draggable
                onChange={() => null}
                maxPreviewFileSize={2097152}
                autoUpload={false}
              >
                <div
                  style={{
                    padding: '8px',
                    paddingLeft: '12px',
                    display: 'inline-table',
                    borderRadius: '12px',
                    textAlign: 'left',
                  }}
                >
                  <Row>
                    <Col xs={4}>
                      <FaFile />
                    </Col>
                    <Col xs={14}>
                      <span>
                        <small
                          style={{
                            fontSize: '0.9em',
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          {_.get(
                            details,
                            'background.filename',
                            'Empty Logo (Please upload logo in PNG format)',
                          )}
                        </small>
                        <br />
                        <small style={{ fontSize: '0.7em' }}>
                          2 MB max file size
                        </small>
                      </span>
                    </Col>
                    <Col xs={3}>
                      <FaPen className="pencil-edit-icon" />
                    </Col>
                    <Col xs={3}>
                      {_.get(details, 'background') && (
                        <FileDownload
                          onClick={async (e: any): Promise<void> => {
                            e.stopPropagation();

                            const url = _.get(details, 'background.url');
                            const file = await fetch(url);
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(await file.blob());
                            link.download = _.get(
                              details,
                              'background.filename',
                            );
                            link.target = '_blank';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                          }}
                          className="download-icon"
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              </Form.Control>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({
              showEditHeaderModal: false,
            });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button onClick={() => this.updateCompanyHeader()} appearance="primary">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderEditAboutCompanyModal = ({ t } = this.props): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showEditAboutCompanyModal')}
      onClose={() => {
        this.setState({
          showEditAboutCompanyModal: false,
          aboutCompany: {
            description: _.get(this, 'state.details.description'),
          },
        });
      }}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form.Group>
          <Form.ControlLabel>
            {t(constant.translation.general.formCompanyDescKey)}
          </Form.ControlLabel>
          <ReactQuill
            ref={(e) => {
              this.quillRef = e?.getEditor();
            }}
            style={{ border: '1px solid #b8b8b8' }}
            theme="snow"
            value={_.get(this.state, 'aboutCompany.description')}
            onChange={(value: any) => {
              this.setState({
                aboutCompany: {
                  // eslint-disable-next-line max-len
                  // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                  ...this.state.aboutCompany,
                  description: value,
                },
              });
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({
              showEditAboutCompanyModal: false,
              aboutCompany: {
                description: _.get(this, 'state.details.description'),
              },
            });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={() => this.updateCompanyDetails('quillRef')}
          appearance="primary"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderEditCompanyDetailsModal = (
    { t } = this.props,
    { companyIndustries, details } = this.state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showEditCompanyDetailsModal')}
      onClose={this.closeEditCompanyDetailsModal}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          key="company-details-form"
          ref={this.formCompanyDetailsRef}
          model={this.formCompanyDetailsSchemaModel}
          onChange={(v: object) =>
            this.onFormValueChanged(v, 'companyDetailsPayload')
          }
          formValue={_.get(this.state, 'companyDetailsPayload')}
          fluid
        >
          {/* Company Logo */}
          <Row>
            <Col xs={8}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constant.translation.general.formCompanyLogoKey)}
                </Form.ControlLabel>
              </Form.Group>
            </Col>
            <Col xs={16}>
              <Form.Control
                name="companyLogo"
                accepter={Uploader}
                fileListVisible={false}
                listType="picture"
                action="#"
                accept="image/png"
                draggable
                onChange={() => null}
                maxPreviewFileSize={2097152}
                autoUpload={false}
              >
                <div
                  style={{
                    padding: '8px',
                    paddingLeft: '12px',
                    display: 'inline-table',
                    borderRadius: '12px',
                    textAlign: 'left',
                  }}
                >
                  <Row>
                    <Col xs={4}>
                      <FaFile />
                    </Col>
                    <Col xs={14}>
                      <span>
                        <small
                          style={{
                            fontSize: '0.9em',
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          {_.get(
                            details,
                            'logo.filename',
                            'Empty Logo (Please upload logo in PNG format)',
                          )}
                        </small>
                        <br />
                        <small style={{ fontSize: '0.7em' }}>
                          2 MB max file size
                        </small>
                      </span>
                    </Col>
                    <Col xs={3}>
                      <FaPen className="pencil-edit-icon" />
                    </Col>
                    <Col xs={3}>
                      <FileDownload
                        onClick={async (e: any): Promise<void> => {
                          e.stopPropagation();

                          const url = _.get(details, 'logo.url');
                          const file = await fetch(url);
                          const link = document.createElement('a');
                          link.href = URL.createObjectURL(await file.blob());
                          link.download = _.get(details, 'logo.filename');
                          link.target = '_blank';
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        }}
                        className="download-icon"
                      />
                    </Col>
                  </Row>
                </div>
              </Form.Control>
            </Col>
          </Row>

          <br />

          {/* Company Name */}
          <Row>
            <Col xs={8}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyNameKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={16}>
              <Form.Control
                autoComplete="off"
                name="name"
                value={_.get(this.state, 'companyDetailsPayload.name')}
                placeholder="Enter your company name"
              />
            </Col>
          </Row>

          <br />

          {/* Company Location */}
          <Row>
            <Col xs={8}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyLocationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={16}>
              <Form.Control
                autoComplete="off"
                name="region"
                value={_.get(this.state, 'companyDetailsPayload.region')}
                placeholder="Enter your company region"
              />
            </Col>
          </Row>

          <br />

          {/* Company Industry */}
          <Row>
            <Col xs={8}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyIndustryKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={16}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="industryId"
                accepter={InputPicker}
                data={_.map(companyIndustries, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'companyDetailsPayload.industryId')}
                placeholder="Select the Industry"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={8}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyAddressKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={16}>
              <Form.Control
                name="address"
                accepter={Textarea}
                value={_.get(this.state, 'companyDetailsPayload.address')}
                placeholder="Enter your company address"
              />
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={8}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanySizeKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={16}>
              <Form.Control
                name="employeeMinSize"
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                accepter={InputPicker}
                value={_.get(this.state, 'companyDetailsPayload.size')}
                data={[
                  {
                    label: '1-10',
                    value: '1-10',
                  },
                  {
                    label: '11-50',
                    value: '11-50',
                  },
                  {
                    label: '51-100',
                    value: '51-100',
                  },
                  {
                    label: '101-200',
                    value: '101-200',
                  },
                  {
                    label: '201-500',
                    value: '201-500',
                  },
                  {
                    label: '501-1000',
                    value: '501-1000',
                  },
                  {
                    label: '1001-5000',
                    value: '1001-5000',
                  },
                  {
                    label: '5001-10000',
                    value: '5001-10000',
                  },
                  {
                    label: '>10000',
                    value: '10001-50000',
                  },
                ]}
                placeholder="51-100"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={8}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyWebKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={16}>
              <Form.Control
                name="website"
                value={_.get(this.state, 'companyDetailsPayload.website')}
                autoComplete="off"
                placeholder="Enter your company website"
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.closeEditCompanyDetailsModal} appearance="subtle">
          Cancel
        </Button>
        <Button
          onClick={() => this.updateCompanyDetails('formCompanyDetailsRef')}
          appearance="primary"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderEditInsightsModal = (
    { t } = this.props,
    { benefits, workingTypes, cultures, loadingInsight } = this.state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showEditInsightsModal')}
      onClose={this.closeEditInsightsModal}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          key="company-insights-form"
          ref={this.formCompanyInsightsRef}
          model={this.formCompanyInsightsSchemaModel}
          onChange={(v: object) =>
            this.onFormValueChanged(v, 'companyInsightsPayload')
          }
          formValue={_.get(this.state, 'companyInsightsPayload')}
          fluid
        >
          {/* Company Benefits */}
          <Row>
            <Col xs={24}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyBenefitsKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              {!loadingInsight && (
                <Form.Control
                  value={_.get(this.state, 'companyInsightsPayload.benefits')}
                  name="benefits"
                  accepter={CheckboxGroup}
                >
                  {benefits.map((b) => (
                    <Checkbox value={b.id}>{b.name}</Checkbox>
                  ))}
                </Form.Control>
              )}

              {loadingInsight && <PlaceholderParagraph active rows={5} />}
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyWorkingTypesKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              {!loadingInsight && (
                <Form.Control
                  value={_.get(
                    this.state,
                    'companyInsightsPayload.workingTypes',
                  )}
                  name="workingTypes"
                  accepter={CheckboxGroup}
                >
                  {workingTypes.map((w) => (
                    <Checkbox value={w.id}>{w.name}</Checkbox>
                  ))}
                </Form.Control>
              )}
              {loadingInsight && <PlaceholderParagraph active rows={5} />}
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyCulturesKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              {!loadingInsight && (
                <Form.Control
                  name="cultures"
                  accepter={CheckboxGroup}
                  inline
                  value={_.get(this.state, 'companyInsightsPayload.cultures')}
                  className="tg-checkbox-group-btn"
                >
                  {cultures.map((c) => (
                    <Checkbox className="tg-checkbox-btn" value={c.id}>
                      {c.name}
                    </Checkbox>
                  ))}
                </Form.Control>
              )}
              {loadingInsight && <PlaceholderParagraph active rows={5} />}
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.closeEditInsightsModal} appearance="subtle">
          Cancel
        </Button>
        <Button
          onClick={() => this.updateCompanyDetails('formCompanyInsightsRef')}
          appearance="primary"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderAddJobPostingModal = (
    { t } = this.props,
    { locations, workingTypes, employmentStatuses, roleCategories } = this
      .state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showAddJobPostingModal')}
      onClose={this.closeAddJobPostingModal}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          key="add-job-posting-form"
          ref={this.formAddPostingRef}
          model={this.formAddPostingSchemaModal}
          onChange={(v: object) =>
            this.onFormValueChanged(v, 'addPostingPayload')
          }
          formValue={_.get(this.state, 'addPostingPayload')}
          fluid
        >
          {/* Job Title */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobTitleKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                autoComplete="off"
                name="title"
                value={_.get(this.state, 'addPostingPayload.title')}
                placeholder="Enter the job title"
              />
            </Col>
          </Row>

          <br />

          {/* Role Category */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formRoleCategoryKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="roleCategoryId"
                accepter={InputPicker}
                data={_.map(roleCategories, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'addPostingPayload.roleCategoryId')}
                placeholder="Select the role category"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobLocationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                name="locationId"
                accepter={SelectPicker}
                appearance="default"
                data={_.map(locations, (m) => ({
                  label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                  value: _.get(m, 'id'),
                }))}
                onOpen={() => {
                  if (locations.length === 0) {
                    this.getLocations();
                  }
                }}
                onSearch={(keyword: string) => {
                  if (keyword !== '') {
                    this.getLocations(keyword);
                  }
                }}
                renderMenu={(menu) => {
                  if (locations.length === 0) {
                    return (
                      <p
                        style={{
                          padding: 4,
                          color: '#999',
                          textAlign: 'center',
                        }}
                      >
                        <Loader /> Loading...
                      </p>
                    );
                  }
                  return menu;
                }}
                size="sm"
                style={{ width: '100%' }}
                placeholder="Choose Location"
              />
            </Col>
          </Row>

          <br />

          {/* Working type */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyWorkingTypesKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="workingType"
                accepter={InputPicker}
                data={_.map(workingTypes, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'addPostingPayload.workingType')}
                placeholder="Select the working type"
              />
            </Col>
          </Row>

          <br />

          {/* Employment status */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyEmployStatusKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                value={_.get(this.state, 'addPostingPayload.empStatus')}
                name="empStatus"
                accepter={RadioGroup}
              >
                {employmentStatuses.map((e) => (
                  <Radio value={e.id}>{e.name}</Radio>
                ))}
              </Form.Control>
            </Col>
          </Row>

          <br />

          {/* Duration */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobDurationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                value={_.get(this.state, 'addPostingPayload.duration')}
                name="duration"
                accepter={SelectPicker}
                style={{ width: '100%' }}
                data={_.range(0, 13).map((v) => ({
                  label: v === 0 ? 'N/A (Permanent)' : `${v} Month(s)`,
                  value: v,
                }))}
              />
            </Col>
          </Row>

          <br />

          {/* Year of experience */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formYOEKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="minYoe"
                  accepter={InputPicker}
                  data={_.range(0, 21).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Min"
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="maxYoe"
                  accepter={InputPicker}
                  data={_.range(0, 21).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Max"
                />
              </Col>
            </Form.Group>
          </Row>

          <br />
          {/* Salary range */}
          <Row>
            <Col xs={24} style={{ marginBottom: '12px' }}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constant.translation.general.formSalaryRangeKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="salaryRange"
                  accepter={RangeSlider}
                  min={500000}
                  step={50000}
                  max={20000000}
                  renderTooltip={(value: number | undefined) =>
                    util.formatCurrency(value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          {/* Show/Hide salary */}
          <Row>
            <Col xs={24} style={{ marginBottom: '12px' }}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constant.translation.general.formSalaryDiscloseKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  style={{ color: 'black', fontWeight: 400, width: '100%' }}
                  name="isShowSalary"
                  accepter={InputPicker}
                  data={[
                    {
                      label: 'Yes',
                      value: 1,
                    },
                    {
                      label: 'No',
                      value: 0,
                    },
                  ]}
                  defaultValue={1}
                  value={_.get(this.state, 'addPostingPayload.isShowSalary')}
                  placeholder="Show Salary?"
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          {/* Custom Technical Test problem */}
          <Row>
            <Form.Group>
              <Form.ControlLabel style={{ marginBottom: '8px' }}>
                {t(constant.translation.general.formCustomTechnicalTest)}
              </Form.ControlLabel>
              <Form.Control
                name="technicalTest"
                placeholder="Enter your custom technical test (URL)"
              />
            </Form.Group>
          </Row>

          <br />

          {/* Responsibility */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formJobResponsibilityKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={24}>
                <ReactQuill
                  ref={(e) => {
                    this.responsibilityRef = e?.getEditor();
                  }}
                  style={{ border: '1px solid #b8b8b8' }}
                  theme="snow"
                  value={_.get(this.state, 'addPostingPayload.description')}
                  onChange={(value: any) => {
                    this.setState({
                      addPostingPayload: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.addPostingPayload,
                        description: value,
                      },
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Row>

          <br />
          {/* Job Requirement */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formJobRequirementKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={24}>
                <ReactQuill
                  ref={(e) => {
                    this.requirementRef = e?.getEditor();
                  }}
                  style={{ border: '1px solid #b8b8b8' }}
                  theme="snow"
                  value={_.get(this.state, 'addPostingPayload.requirements')}
                  onChange={(value: any) => {
                    this.setState({
                      addPostingPayload: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.addPostingPayload,
                        requirements: value,
                      },
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.closeAddJobPostingModal} appearance="subtle">
          Cancel
        </Button>
        <Button onClick={this.submitJobPosting} appearance="primary">
          Upload Job
        </Button>
      </Modal.Footer>
    </Modal>
  );

  render(): JSX.Element | null {
    return (
      <>
        {this.renderAddJobPostingModal()}
        {this.renderEditInsightsModal()}
        {this.renderEditAboutCompanyModal()}
        {this.renderEditCompanyDetailsModal()}
        {this.renderEditHeaderModal()}

        <Grid className="home-static-authorized-container" fluid>
          <Row>
            {this.renderSideTabs()}
            {this.renderMainContent()}
          </Row>
        </Grid>
      </>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerMyCompanyScreen));
