import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  FaCheck,
  FaChevronDown,
  FaChevronLeft,
  FaChevronRight,
  FaComments,
  FaEye,
  FaEyeSlash,
  FaFileDownload,
  FaHome,
  FaPlus,
  FaTimes,
  FaUser,
} from 'react-icons/fa';
import {
  Affix,
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  Grid,
  IconButton,
  Input,
  InputPicker,
  Loader,
  Modal,
  Pagination,
  Panel,
  Popover,
  Radio,
  RadioGroup,
  RangeSlider,
  Row,
  Schema,
  SelectPicker,
  Stack,
  Table,
  Tooltip,
  Uploader,
  Whisper,
  toaster,
} from 'rsuite';
import { constant, util } from '@helper';
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph';
import {
  CompanyAPI,
  DocumentAPI,
  JobApplicationAPI,
  MiscAPI,
} from '@services/api';
import { Promise } from 'bluebird';
import { Icon } from '@rsuite/icons';
import ReactQuill from 'react-quill';
import AuthStorage from '@common/services/authStorage';
import { ValueType } from 'rsuite/esm/Checkbox';
import moment from 'moment';
import { FileType } from 'rsuite/esm/Uploader';

const { withRouter } = util;
const { StringType, NumberType, ArrayType } = Schema.Types;

type State = {
  loading: boolean;
  loadingTable: boolean;
  showModal: boolean;
  files: FileType[];
  type: string;

  opts: any;
  rejectionReason: string;
  disabled: boolean;
  templateLink: string;

  selectedJobId: number;
  selectedJob: object | any;
  results: Array<object>;
  summary: any;
  applications: Array<object>;
  applicationId: number;
  applicationStatus: string;
  applicationRole: string;
  applicantName: string;

  showAddJobPostingModal: boolean;
  showShortlistModal: boolean;

  addPostingPayload: any;

  totalApplications: number;
  page: number;
  jobListPage: number;
  jobListTotalPage: number;

  // misc
  filters: ValueType[];
  locations: Array<any>;
  workingTypes: Array<any>;
  roleCategories: Array<any>;
  employmentStatuses: Array<any>;
  toggles: object;
};

class HiringPartnerDashboardScreen extends React.Component<any, State> {
  responsibilityRef: any;

  requirementRef: any;

  formAddPostingSchemaModal: any;

  formAddPostingRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      loadingTable: true,
      showModal: false,
      files: [],

      opts: {},
      rejectionReason: '',
      disabled: false,
      templateLink: '',

      summary: {},

      type: '',
      applicationId: 0,
      applicationStatus: '',
      applicantName: '',
      applicationRole: '',

      showAddJobPostingModal: false,
      showShortlistModal: false,
      addPostingPayload: {},

      results: [],
      applications: [],
      selectedJobId: 0,
      selectedJob: {},

      totalApplications: 0,
      page: 1,
      jobListPage: 1,
      jobListTotalPage: 1,

      // misc
      filters: [],
      locations: [],
      workingTypes: [],
      roleCategories: [],
      employmentStatuses: [],
      toggles: {},
    };

    this.requirementRef = React.createRef();
    this.responsibilityRef = React.createRef();
    this.formAddPostingRef = React.createRef();
    this.formAddPostingSchemaModal = Schema.Model({
      title: StringType().isRequired('Please fill the required field'),
      duration: NumberType()
        .min(0)
        .isRequired('Please fill the required field'),
      technicalTest: StringType().isURL(),
      locationId: NumberType().isRequired('Please fill the required field'),
      empStatus: NumberType().isRequired('Please fill the required field'),
      workingType: NumberType().isRequired('Please fill the required field'),
      roleCategoryId: NumberType().isRequired('Please fill the required field'),
      minYoe: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.maxYoe) {
            return Number(value) < Number(data.maxYoe);
          }

          return true;
        }, 'Year start should be less than year end'),
      maxYoe: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.minYoe) {
            return Number(value) > Number(data.minYoe);
          }

          return true;
        }, 'Year end should be greater than year start'),
      salaryRange: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(2),
    });
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getJobList();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getSummary();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getOfferLetterTemplate();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
  ): void {
    const { selectedJobId, filters } = this.state;
    if (prevState.selectedJobId !== selectedJobId && selectedJobId > 0) {
      if (filters.length > 0) {
        this.onFilterChanged(filters);
      } else {
        this.getApplications(selectedJobId);
      }
    }
  }

  private getOfferLetterTemplate = async (): Promise<void> => {
    const year = moment().format('YYYY');
    const response = await MiscAPI.getTemplates(`OFFERINGLETTER_${year}`);
    const link = _.get(response, 'data.0.url');
    this.setState({
      templateLink: link || '',
    });
  };

  private getCompanyWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
    });
  };

  private getRoleCategories = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('ROLE_CATEGORY');
    const data = _.get(response, 'data') || [];
    this.setState({
      roleCategories: data,
    });
  };

  private getEmploymentStatus = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('EMPLOYMENT_STATUS');
    const data = _.get(response, 'data') || [];
    this.setState({
      employmentStatuses: data,
    });
  };

  private onFilterChanged = async (filters: ValueType[]): Promise<void> => {
    const { selectedJobId } = this.state;
    if (selectedJobId === 0) {
      this.setState({ loadingTable: false });
      return;
    }

    this.setState({ loadingTable: true });

    const { page } = this.state;
    const limit = 10;

    const response = await JobApplicationAPI.allByPostingIdWithFilter(
      selectedJobId,
      filters,
      page,
      limit,
    );
    const data = _.get(response, 'data') || [];
    const opts = _.get(response, 'meta.options');
    const count = _.get(response, 'meta.count') || 0;
    let totalApplications = _.get(response, 'meta.total') || 0;

    if (_.includes(filters, 'highestEnglish')) {
      if (count < 10) {
        totalApplications = count;
      } else if (totalApplications > 10) {
        let br = false;
        let i = 1;
        let total = limit * page;

        while (!br) {
          const responseNextPage =
            // eslint-disable-next-line no-await-in-loop
            await JobApplicationAPI.allByPostingIdWithFilter(
              selectedJobId,
              filters,
              page + i,
              limit,
            );
          const countNextPage = _.get(responseNextPage, 'meta.count');

          if (countNextPage === limit) {
            total += limit;
          } else {
            // countNextPage < limit
            totalApplications = total + (countNextPage || 0);
            br = true;
          }

          i += 1;
        }
      }
    }

    this.setState({
      loadingTable: false,
      applications: data,
      opts,
      filters,
      totalApplications,
    });
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private uploadDocument = async (
    id: number,
    { files } = this.state,
  ): Promise<void> => {
    if (files.length === 0) {
      return;
    }

    try {
      const blob = {
        blobFile: _.get(files, '0.blobFile'),
        name: `offering_letter_file.pdf`,
      };
      const base64 = await util.blobToBase64(blob.blobFile);
      await DocumentAPI.upload({
        content: base64,
        table: 'App\\Models\\JobApplication',
        tableId: id,
        filename: blob.name,
      });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private onApproveButtonClicked = async (
    e: any,
    id: number,
    status: string,
  ): Promise<void> => {
    try {
      if (status === 'TECHNICAL_TEST_SUBMITTED_BY_CANDIDATE') {
        await JobApplicationAPI.updateStatusTo(id, 'TECHNICAL_TEST_PASSED');
      } else if (status === 'TECHNICAL_TEST_PASSED') {
        await JobApplicationAPI.updateStatusTo(id, 'INTERVIEW_IN_PROGRESS');
      } else if (
        status === 'INTERVIEW_IN_PROGRESS' ||
        status === 'INTERVIEW_ACCEPTED'
      ) {
        this.uploadDocument(id);
        await JobApplicationAPI.updateStatusTo(id, 'INTERVIEW_ACCEPTED');
      }

      toaster.push(
        util.buildMessage('Application has been succcessfully approved.'),
      );

      const { selectedJobId } = this.state;

      this.setState({
        applications: [],
        showModal: false,
        showShortlistModal: false,
      });

      this.getSummary();
      this.getApplications(selectedJobId);
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  private onRejectButtonClicked = async (
    e: any,
    id: number,
    status: string,
  ): Promise<void> => {
    try {
      if (status === 'TECHNICAL_TEST_SUBMITTED_BY_CANDIDATE') {
        await JobApplicationAPI.updateStatusToWithData(
          id,
          { rejection_reason: _.get(this.state, 'rejectionReason') },
          'REJECTED_BY_HIRING_PARTNER',
        );
      } else if (status === 'TECHNICAL_TEST_PASSED') {
        await JobApplicationAPI.updateStatusToWithData(
          id,
          { rejection_reason: _.get(this.state, 'rejectionReason') },
          'SHORTLIST_REJECTED',
        );
      } else if (status === 'INTERVIEW_IN_PROGRESS') {
        await JobApplicationAPI.updateStatusToWithData(
          id,
          { rejection_reason: _.get(this.state, 'rejectionReason') },
          'INTERVIEW_REJECTED_BY_HIRING_PARTNER',
        );
      }

      toaster.push(
        util.buildMessage('Application has been succcessfully rejected.'),
      );

      const { selectedJobId } = this.state;

      this.setState({
        applications: [],
        showModal: false,
        showShortlistModal: false,
      });

      this.getSummary();
      this.getApplications(selectedJobId);
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });

    window.location.reload();
  };

  private getApplications = async (jobPostingId: number): Promise<void> => {
    if (jobPostingId === 0) {
      this.setState({ loadingTable: false });
      return;
    }

    this.setState({ loadingTable: true });

    const { page } = this.state;

    const response = await JobApplicationAPI.allByPostingId(
      jobPostingId,
      page,
      10,
    );
    const data = _.get(response, 'data') || [];
    const opts = _.get(response, 'meta.options');
    const totalApplications = _.get(response, 'meta.total') || 0;

    this.setState({
      loadingTable: false,
      applications: data,
      opts,
      totalApplications,
    });
  };

  private getJobList = async (page = 1): Promise<void> => {
    try {
      this.setState({ loading: true });
      const res = await CompanyAPI.searchJobs(
        null,
        {},
        page,
        window.innerWidth <= 768 ? 99 : 5,
      );
      const results: Array<any> = _.get(res, 'data') || [];
      const totalPage = _.get(res, 'meta.totalPage') || 1;

      let selectedJobId = 0;
      let selectedJob = {};
      if (results.length > 0) {
        selectedJobId = results[0].id;
        selectedJob = _.get(results, '0');
      }

      this.getApplications(selectedJobId);

      this.setState({
        loading: false,
        page: 1,
        jobListPage: page,
        jobListTotalPage: totalPage,
        results,
        selectedJobId,
        selectedJob,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getLocations = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      this.setState({ locations: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getSummary = async (): Promise<void> => {
    try {
      const res = await JobApplicationAPI.summary();
      const summary: any = _.get(res, 'data');

      this.setState({
        summary,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private approveShortlist = (e: any, rowData: any): void => {
    e.stopPropagation();
    this.setState({
      showShortlistModal: true,
      type: 'approve',
      applicationId: rowData.id,
      applicationStatus: rowData.lastStatus,
      applicationRole: rowData.jobPosting.title,
      applicantName: rowData.user.name,
    });
  };

  private rejectShortlist = (e: any, rowData: any): void => {
    e.stopPropagation();
    this.setState({
      showShortlistModal: true,
      type: 'reject',
      disabled: true,
      applicationId: rowData.id,
      applicationStatus: rowData.lastStatus,
      applicationRole: rowData.jobPosting.title,
      applicantName: rowData.user.name,
    });
  };

  closeAddJobPostingModal = (): void => {
    this.setState({
      showAddJobPostingModal: false,
      addPostingPayload: {},
    });
  };

  submitJobPosting = async (): Promise<void> => {
    const { t } = this.props;

    try {
      if (!_.invoke(this.formAddPostingRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      // Check description field, is it empty?
      if (this.responsibilityRef.getLength() <= 1) {
        throw new Error('Please fill the job description field!');
      }

      // Check requirement field, is it empty?
      if (this.requirementRef.getLength() <= 1) {
        throw new Error('Please fill the job requirements field!');
      }

      const storage = AuthStorage.getInstance();
      const user = storage.getUser();
      const { addPostingPayload } = this.state;
      const { workingType, salaryRange } = addPostingPayload;
      const technicalTestUrl = _.get(addPostingPayload, 'technicalTest');

      let technicalTestId = null;

      if (technicalTestUrl) {
        const companyName = _.get(user, 'company.name');
        const jobTitle = _.get(addPostingPayload, 'title');
        const name = `${companyName} | ${jobTitle}`;
        const resp = await MiscAPI.addDataTemplate({
          url: technicalTestUrl,
          name,
        });
        technicalTestId = _.get(resp, 'data.id');
      }

      const payload = {
        ...addPostingPayload,
        technicalTestId,
        companyId: _.get(user, 'companyId'),
        description: _.get(
          this.responsibilityRef,
          'container.firstChild.innerHTML',
        ),
        requirements: _.get(
          this.requirementRef,
          'container.firstChild.innerHTML',
        ),
        minSalaryRange: _.get(salaryRange, '0'),
        maxSalaryRange: _.get(salaryRange, '1'),
        hot: 1,
        active: 1,
      };

      _.unset(payload, 'workingType');
      _.unset(payload, 'salaryRange');
      _.unset(payload, 'technicalTest');

      await CompanyAPI.addPosting(payload, { workingType });

      this.setState({
        showAddJobPostingModal: false,
        addPostingPayload: {},
      });

      this.getJobList(1);

      toaster.push(util.buildMessage('Successfully added new job posting!'));
    } catch (err: any) {
      toaster.push(util.buildErrorMessage(err.message));
    }
  };

  renderShortlistModal = (
    {
      applicationId,
      type,
      applicationStatus,
      applicantName,
      applicationRole,
    } = this.state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      overflow={false}
      open={_.get(this.state, 'showShortlistModal')}
      onClose={() => {
        this.setState({
          showShortlistModal: false,
          disabled: false,
        });
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-interview-hp.png`}`}
          className="bg-interview-modal-hp"
          alt="bg-img"
        />
        <h3>
          {type === 'reject' &&
            'Are you sure you want to reject the applicant?'}

          {type !== 'reject' &&
            `Are you sure that you want to shortlist 
            the candidate ${applicantName} - ${applicationRole}?`}
        </h3>
        <br />
        <p>
          {type === 'reject' && (
            <div className="mt-4 text-align-left">
              <small>
                Rejection Reason ({applicantName} -{applicationRole}):
              </small>
              <Input
                as="textarea"
                className="mt-2"
                rows={5}
                required
                onChange={(val) =>
                  this.setState({
                    rejectionReason: val,
                    disabled: val.length < 20,
                  })
                }
                // eslint-disable-next-line max-len
                placeholder="Please enter the reason why you reject this application. (Min 20 characters)"
              />
            </div>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({ showShortlistModal: false, disabled: false });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            if (type === 'reject') {
              this.onRejectButtonClicked(e, applicationId, applicationStatus);
            } else {
              this.onApproveButtonClicked(e, applicationId, applicationStatus);
            }
          }}
          appearance="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderModal = (
    {
      applicationId,
      type,
      templateLink,
      applicationStatus,
      applicantName,
      applicationRole,
    } = this.state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      overflow={false}
      open={_.get(this.state, 'showModal')}
      onClose={() => {
        this.setState({ showModal: false, files: [], disabled: false });
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-interview-hp.png`}`}
          className="bg-interview-modal-hp"
          alt="bg-img"
        />
        <h3>
          {type === 'reject' &&
            'Are you sure you want to reject the applicant?'}

          {type !== 'reject' &&
            `Are you sure that you want to extend the final offer 
            to ${applicantName} - ${applicationRole}?`}
        </h3>
        <br />
        <p>
          {type === 'reject' && (
            <div className="mt-4 text-align-left">
              <small>
                Rejection Reason ({applicantName} -{applicationRole}):
              </small>
              <Input
                as="textarea"
                className="mt-2"
                rows={5}
                required
                onChange={(val) =>
                  this.setState({
                    rejectionReason: val,
                    disabled: val.length < 20,
                  })
                }
                // eslint-disable-next-line max-len
                placeholder="Please enter the reason why you reject this application. (Min 20 characters)"
              />
            </div>
          )}

          {type !== 'reject' && (
            <div className="upload-offer-section">
              <h5>Upload Offering Letter</h5>
              <p>
                Make sure the uploaded documents are correct before submit them.
                You can not upload the document again if there are any errors.
              </p>
              <p className="description">
                Offering Letter template example:{' '}
                <a href={templateLink} target="_blank" rel="noreferrer">
                  here
                </a>
                <br />
                *Optional (you can use your version)
              </p>

              <Uploader
                multiple={false}
                // eslint-disable-next-line max-len
                accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                draggable
                action="#"
                maxPreviewFileSize={2097152}
                autoUpload={false}
                onChange={(files) => this.setState({ files })}
              >
                <div
                  style={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <span>
                    Drag & drop your files here or <a href="#">choose file</a>
                    <br />
                    <br />
                    <small>2 MB max file size (*.PDF, *.doc, *.docx)</small>
                  </span>
                </div>
              </Uploader>
            </div>
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({ showModal: false, files: [], disabled: false });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            if (type === 'reject') {
              this.onRejectButtonClicked(e, applicationId, applicationStatus);
            } else {
              this.onApproveButtonClicked(e, applicationId, applicationStatus);
            }
            this.setState({ files: [] });
          }}
          disabled={_.get(this.state, 'disabled')}
          appearance="primary"
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/hiring-partner/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/hiring-partner/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/hiring-partner/mycompany')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/hiring-partner/mycompany',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            My Company
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderMyOverview = ({ summary } = this.state): JSX.Element => (
    <FlexboxGrid justify="space-around">
      <FlexboxGrid.Item as={Col} xs={12} sm={12} md={6} colspan={4}>
        <Panel className="myoverview-panel" id="job-applied" bordered>
          <p>Total Applicants</p>
          <b>{summary.totalApplicants || 0}</b>
          <small>Applicants</small>
        </Panel>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} xs={12} sm={12} md={6} colspan={4}>
        <Panel className="myoverview-panel" id="interview-phase" bordered>
          <p>Waiting for Interview</p>
          <b>{summary.waitingForInterview || 0}</b>
          <small>Applicants</small>
        </Panel>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} xs={12} sm={12} md={6} colspan={4}>
        <Panel className="myoverview-panel" id="accepted-phase" bordered>
          <p>Accepted Applicants</p>
          <b>{summary.acceptedApplicants || 0}</b>
          <small>Applicants</small>
        </Panel>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item as={Col} xs={12} sm={12} md={6} colspan={4}>
        <Panel className="myoverview-panel" id="rejected-phase" bordered>
          <p>Rejected Applicants</p>
          <b>{summary.rejectedApplicants || 0}</b>
          <small>Applicants</small>
        </Panel>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );

  renderActionButton = (rowData: any, opts: any): JSX.Element => {
    if (
      _.includes(
        [
          'INTERVIEW_REJECTED_BY_HIRING_PARTNER',
          'REJECTED_BY_SYSTEM',
          'REJECTED_BY_HIRING_PARTNER',
          'REJECTED_BY_ADMIN',
          'REJECTED_BY_REMINDER_SYSTEM',
          'GENERAL_TEST_EXPIRED',
          'WAITING_FOR_TRANSFER_JOB_CONFIRMATION',
          'TECHNICAL_TEST_EXPIRED',
          'SHORTLIST_REJECTED',
        ],
        rowData.lastStatus,
      )
    ) {
      return <div className="btn-tg-pill-shaped failed">Rejected</div>;
    }

    if (
      _.includes(['INTERVIEW_ACCEPTED', 'OFFER_ACCEPTED'], rowData.lastStatus)
    ) {
      return (
        <>
          {rowData.lastStatus === 'INTERVIEW_ACCEPTED' && (
            <Button
              block
              appearance="primary"
              className="btn-tg-pill-shaped success"
              onClick={(e: any) => {
                e.stopPropagation();
                this.setState({
                  showModal: true,
                  type: 'approve',
                  applicationId: rowData.id,
                  applicationStatus: rowData.lastStatus,
                  applicationRole: rowData.jobPosting.title,
                  applicantName: rowData.user.name,
                });
              }}
            >
              Re-upload
            </Button>
          )}
          {rowData.lastStatus === 'OFFER_ACCEPTED' && (
            <div className="btn-tg-pill-shaped success">Accepted</div>
          )}
          {rowData.signedOfferLetter && (
            <>
              <br />
              <IconButton
                appearance="default"
                size="md"
                block
                className="btn-tg-pill-shaped"
                onClick={async (e: any) => {
                  e.stopPropagation();

                  const url = _.get(rowData, 'signedOfferLetter.url');
                  const file = await fetch(url);
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(await file.blob());
                  link.download = _.get(rowData, 'signedOfferLetter.filename');
                  link.target = '_blank';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
                icon={<Icon as={FaFileDownload} />}
              />
            </>
          )}
        </>
      );
    }

    if (
      !_.includes(
        ['TECHNICAL_TEST_PASSED', 'INTERVIEW_IN_PROGRESS'],
        rowData.lastStatus,
      )
    ) {
      return (
        <div className="btn-tg-pill-shaped info">
          {_.get(opts, rowData?.lastStatus)}
        </div>
      );
    }

    if (rowData?.lastStatus === 'TECHNICAL_TEST_PASSED') {
      return (
        <Row>
          <Col xs={24}>
            <Button
              className="btn-tg-pill-shaped success"
              block
              onClick={(e) => this.approveShortlist(e, rowData)}
            >
              Shortlist
            </Button>
          </Col>
          <Col xs={24}>
            <Button
              className="btn-tg-pill-shaped failed"
              style={{ marginTop: '.5em' }}
              block
              onClick={(e) => this.rejectShortlist(e, rowData)}
            >
              Reject
            </Button>
          </Col>
        </Row>
      );
    }

    const applicantName = _.get(rowData, 'user.name');
    const user = AuthStorage.getInstance().getUser();
    const hpName = _.get(user, 'name');
    const companyName = _.get(user, 'company.name');
    const roleName = _.get(rowData, 'jobPosting.title');
    const message =
      `Hi ${applicantName}, I am ${hpName} from ${companyName}.` +
      ` I saw your profile from Talent Growth and` +
      ` I am interested to proceed you to next interview stage` +
      ` for ${roleName}.` +
      ` Will you be available for the interview on` +
      ` [DD/MM/YY] at [time] ?`;

    return (
      <Stack direction="row" className="action-btn-group" spacing={8}>
        {rowData?.lastStatus === 'INTERVIEW_IN_PROGRESS' &&
          rowData.user.phone && (
            <Stack.Item>
              <IconButton
                appearance="default"
                size="md"
                className="action-btn comments"
                onClick={async (e: any) => {
                  e.stopPropagation();
                  window.open(
                    // eslint-disable-next-line max-len
                    `https://api.whatsapp.com/send?phone=${rowData.user.phone.substring(
                      1,
                    )}&text=${encodeURI(message)}`,
                  );

                  const status = _.get(rowData, 'lastStatus');
                  const id = _.get(rowData, 'id');
                  if (status !== 'INTERVIEW_IN_PROGRESS') {
                    // eslint-disable-next-line max-len
                    await JobApplicationAPI.updateStatusToInterviewInProgress(
                      id,
                    );
                  }
                }}
                icon={<Icon as={FaComments} />}
              />
            </Stack.Item>
          )}

        {rowData?.lastStatus === 'INTERVIEW_IN_PROGRESS' && (
          <Stack.Item>
            <IconButton
              appearance="default"
              size="md"
              className="action-btn check"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  showModal: true,
                  type: 'approve',
                  applicationId: rowData.id,
                  applicationStatus: rowData.lastStatus,
                  applicationRole: rowData.jobPosting.title,
                  applicantName: rowData.user.name,
                });
              }}
              icon={<Icon as={FaCheck} />}
            />
          </Stack.Item>
        )}

        {rowData?.lastStatus === 'INTERVIEW_IN_PROGRESS' && (
          <Stack.Item>
            <IconButton
              appearance="default"
              size="md"
              className="action-btn times"
              icon={<Icon as={FaTimes} />}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  showModal: true,
                  type: 'reject',
                  disabled: true,
                  applicationId: rowData.id,
                  applicationStatus: rowData.lastStatus,
                  applicationRole: rowData.jobPosting.title,
                  applicantName: rowData.user.name,
                });
              }}
            />
          </Stack.Item>
        )}
      </Stack>
    );
  };

  showHideCandidateName = (rowData: any): void => {
    const toggles = _.get(this, 'state.toggles');
    this.setState({
      toggles: {
        ...toggles,
        [rowData.id]: !_.get(toggles, rowData.id),
      },
    });
  };

  renderCandidateName = (rowData: any): string => {
    const toggles = _.get(this, 'state.toggles');
    const state = _.get(toggles, rowData.id);
    if (state) {
      return _.get(rowData, 'user.name');
    }

    return String(rowData.lastStatus).includes('REJECT') ||
      (String(rowData.lastStatus).includes('INTERVIEW') &&
        rowData.jobPosting.active === 0) ||
      rowData.lastStatus === 'WAITING_FOR_TRANSFER_JOB_CONFIRMATION'
      ? '*****'
      : _.get(rowData, 'user.name');
  };

  renderTableInDesktopView = (
    loadingTable: boolean,
    applications: any[],
    opts: any,
  ): JSX.Element | null => (
    <Table
      autoHeight
      loading={loadingTable}
      data={applications}
      bordered
      headerHeight={108}
      rowHeight={(rowData: any) => {
        const text = _.get(rowData, 'user.profile.university');

        if (String(text).length <= 29 && String(text).length >= 18) {
          return 148;
        }

        if (String(text).length > 29) {
          return 178;
        }

        return 123;
      }}
      onRowClick={(rowData) => {
        if (!String(rowData.lastStatus).includes('REJECT')) {
          const url = `/dashboard/hiring-partner/application/${_.get(
            rowData,
            'id',
          )}`;
          const win = window.open(url, '_blank');
          win?.focus();
        }
      }}
    >
      {/* Name */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.Cell fullText>
          {(rowData) => {
            return (
              <div>
                <b>{this.renderCandidateName(rowData)}</b>

                {_.get(rowData, 'lastStatus') ===
                  'REJECTED_BY_REMINDER_SYSTEM' && (
                  <IconButton
                    onClick={() => this.showHideCandidateName(rowData)}
                    appearance="subtle"
                    className={`icon-tg-eye-btn ${
                      !_.get(this.state, `toggles.${rowData.id}`) && 'hide'
                    }`}
                    icon={
                      _.get(this.state, `toggles.${rowData.id}`) ? (
                        <FaEyeSlash />
                      ) : (
                        <FaEye />
                      )
                    }
                  />
                )}
                <br />
                {util.printGender(rowData.user.profile.gender)}
              </div>
            );
          }}
        </Table.Cell>
      </Table.Column>

      {/* Education */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Education</Table.HeaderCell>
        <Table.Cell fullText>
          {(rowData) => {
            const rawUniv = _.get(rowData.user, 'profile.university');
            const gpa =
              _.round(_.get(rowData.user, 'profile.gpa') || 0, 2).toFixed(2) ||
              '-';
            const yearEnd = _.get(rowData.user, 'profile.yearEnd');
            const yearStart = _.get(rowData.user, 'profile.yearStart');
            const currYear = Number(moment().format('YYYY'));
            const currMonth = Number(moment().format('MM'));

            let semester: number | string = 0;
            let isOverLength = false;
            let univ: string = rawUniv || '';

            if (Number(yearEnd) < currYear) {
              semester = 'Graduated';
            } else if (Number(yearEnd) === currYear) {
              if (currMonth > 6) {
                semester = 'Graduated';
              } else {
                semester = 8;
              }
            } else {
              // yearEnd < currYear
              const y = (currYear - Number(yearStart)) * 2;

              if (y === 0) {
                semester = 'Semester 1';
              } else if (currMonth >= 6) {
                semester = `Semester ${y + 1}`;
              } else {
                semester = `Semester ${y}`;
              }
            }

            if (univ.length > 58) {
              isOverLength = true;
              univ = `${univ.substring(0, 58)}...`;
            }

            const eduInfo: any = (
              <>
                <br />
                <span style={{ fontSize: '0.85em' }}>GPA: {gpa}</span>{' '}
                <span style={{ fontSize: '0.85em', color: '#E9A0FF' }}>| </span>
                <span style={{ fontSize: '0.85em' }}>{semester}</span>
              </>
            );

            return isOverLength ? (
              <div>
                <Whisper
                  placement="topStart"
                  speaker={
                    <Tooltip>
                      <b>{rawUniv}</b>
                    </Tooltip>
                  }
                >
                  <div>{univ}</div>
                </Whisper>
                {eduInfo}
              </div>
            ) : (
              <div>
                <div>{univ}</div>
                {eduInfo}
              </div>
            );
          }}
        </Table.Cell>
      </Table.Column>

      {/* English Proficiency */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>English Proficiency</Table.HeaderCell>
        <Table.Cell fullText>
          {(rowData) => {
            let proficiency = _.get(rowData.user, 'profile.englishProficiency');

            if (proficiency === 'NO_BASIC') {
              proficiency = "Can't Speak English";
            } else if (proficiency === 'BASIC') {
              proficiency = 'Basic';
            } else if (proficiency === 'INTERMEDIATE') {
              proficiency = 'Intermediate';
            } else {
              proficiency = 'Advanced';
            }

            return <p>{proficiency}</p>;
          }}
        </Table.Cell>
      </Table.Column>

      {/* General Test Score */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>General Test</Table.HeaderCell>
        <Table.Cell fullText>
          {(rowData) => {
            return String(
              _.get(rowData.user, 'generalTestScore') === null
                ? '-'
                : _.get(rowData.user, 'generalTestScore'),
            );
          }}
        </Table.Cell>
      </Table.Column>

      {/* Action items */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.Cell>
          {(rowData) => this.renderActionButton(rowData, opts)}
        </Table.Cell>
      </Table.Column>
    </Table>
  );

  renderTableInMobileView = (
    loadingTable: boolean,
    applications: any[],
    opts: any,
  ): JSX.Element | null => (
    <Table
      autoHeight
      loading={loadingTable}
      data={applications}
      bordered
      headerHeight={80}
      rowHeight={320}
      onRowClick={(rowData) => {
        if (!String(rowData.lastStatus).includes('REJECT')) {
          const url = `/dashboard/hiring-partner/application/${_.get(
            rowData,
            'id',
          )}`;
          const win = window.open(url, '_blank');
          win?.focus();
        }
      }}
    >
      {/* Name */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Candidate List</Table.HeaderCell>
        <Table.Cell fullText>
          {(rowData) => {
            const gpa = _.get(rowData.user, 'profile.gpa') || '-';
            let proficiency = _.get(rowData, 'user.profile.englishProficiency');

            if (proficiency === 'NO_BASIC') {
              proficiency = "Can't Speak English";
            } else if (proficiency === 'BASIC') {
              proficiency = 'Basic';
            } else if (proficiency === 'INTERMEDIATE') {
              proficiency = 'Intermediate';
            } else {
              proficiency = 'Advanced';
            }

            const rawUniv = _.get(rowData.user, 'profile.university');
            let univ: string = rawUniv || '';
            let isOverLength = false;
            if (univ.length > 36) {
              isOverLength = true;
              univ = `${univ.substring(0, 36)}... (GPA: ${gpa})`;
            }

            return (
              <>
                <div>
                  <b>
                    {String(rowData.lastStatus).includes('REJECT') ||
                    (String(rowData.lastStatus).includes('INTERVIEW') &&
                      rowData.jobPosting.active === 0) ||
                    rowData.lastStatus ===
                      'WAITING_FOR_TRANSFER_JOB_CONFIRMATION'
                      ? '*****'
                      : _.get(rowData, 'user.name')}
                  </b>
                </div>
                <div>
                  {util.printGender(_.get(rowData, 'user.profile.gender'))}
                </div>
                <div style={{ marginTop: '1em' }}>
                  <p>
                    {isOverLength ? (
                      <Whisper
                        placement="topStart"
                        speaker={
                          <Tooltip>
                            <b>{rawUniv}</b>
                          </Tooltip>
                        }
                      >
                        <div>{univ}</div>
                      </Whisper>
                    ) : (
                      <>
                        {univ} (GPA: {gpa})
                      </>
                    )}
                  </p>
                </div>
                <div style={{ marginTop: '1em' }}>
                  <p>{proficiency}</p>
                </div>
                <div style={{ marginTop: '1em' }}>
                  General Test:{' '}
                  {String(
                    _.get(rowData.user, 'generalTestScore') === null
                      ? '-'
                      : _.get(rowData.user, 'generalTestScore'),
                  )}
                </div>
              </>
            );
          }}
        </Table.Cell>
      </Table.Column>

      {/* Status */}
      <Table.Column flexGrow={1} align="center">
        <Table.HeaderCell>Status</Table.HeaderCell>
        <Table.Cell fullText>
          {(rowData) => this.renderActionButton(rowData, opts)}
        </Table.Cell>
      </Table.Column>
    </Table>
  );

  renderJobListing = (): JSX.Element | null => {
    const {
      loading,
      loadingTable,
      results,
      selectedJobId,
      selectedJob,
      applications,
      totalApplications,
      page,
      jobListPage,
      jobListTotalPage,
      opts,
    } = this.state;

    return (
      <FlexboxGrid>
        <FlexboxGrid.Item
          as={Col}
          xsHidden
          smHidden
          mdHidden
          lg={6}
          colspan={4}
        >
          <Panel className="job-list-panel" bordered>
            <h2>
              Job Lists
              {jobListTotalPage > jobListPage && !loading && (
                <FaChevronRight
                  style={{
                    marginTop: '16px',
                    float: 'right',
                    width: '14px',
                    height: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.getJobList(jobListPage + 1)}
                />
              )}
              {jobListPage > 1 && !loading && (
                <FaChevronLeft
                  style={{
                    marginRight: '.5em',
                    marginTop: '16px',
                    float: 'right',
                    width: '14px',
                    height: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={() => this.getJobList(jobListPage - 1)}
                />
              )}
            </h2>

            {loading &&
              _.range(0, 4).map((val: number) => (
                <Stack key={val} spacing={8} className="loading-skeleton">
                  <PlaceholderParagraph active />
                </Stack>
              ))}

            {!loading &&
              results.map((obj: any) => (
                <Stack spacing={8} key={obj.id}>
                  <Panel
                    className={`job-list-search-results ${
                      obj.id === selectedJobId ? 'active' : ''
                    }`}
                    onClick={() => {
                      const changed = {
                        selectedJobId: obj.id,
                        selectedJob: obj,
                      };

                      if (obj.id !== selectedJobId) {
                        this.setState({ filters: [] }, () => {
                          this.setState(changed);
                        });
                        return;
                      }

                      this.setState(changed);
                    }}
                  >
                    <Row>
                      <Col xs={20}>
                        <p>{_.get(obj, 'title')}</p>
                        <small>{_.get(obj, 'createdAt')}</small>
                      </Col>

                      <Col xs={4}>
                        <FaChevronRight style={{ marginTop: '16px' }} />
                      </Col>
                    </Row>
                  </Panel>
                </Stack>
              ))}
          </Panel>
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} xs={24} sm={24} md={24} lg={18} colspan={4}>
          <div className="filter-panel">
            <Row>
              <Col xs={14}>
                <p>Job Listing</p>
                {loading && <PlaceholderParagraph rows={1} active />}
                {window.innerWidth > 768 && !loading && (
                  <b className="job-title">
                    {_.get(selectedJob, 'title', '-')}
                  </b>
                )}

                {window.innerWidth <= 768 && !loading && (
                  <SelectPicker
                    appearance="subtle"
                    className="job-picker"
                    data={results.map((r: any) => ({
                      label: _.get(r, 'title'),
                      value: _.get(r, 'id'),
                    }))}
                    renderValue={(val) => (
                      <>
                        <b>{_.get(_.find(results, { id: val }), 'title')}</b>
                        <FaChevronDown style={{ marginLeft: '0.5em' }} />
                      </>
                    )}
                    value={_.get(selectedJob, 'id')}
                    searchable={false}
                    onSelect={(value: any) => {
                      const changed = {
                        selectedJobId: value,
                        selectedJob: _.find(results, { id: value }),
                      };

                      if (value !== selectedJobId) {
                        this.setState({ filters: [] }, () => {
                          this.setState(changed);
                        });
                        return;
                      }

                      this.setState(changed);
                    }}
                    placeholder="Select Job Title"
                  />
                )}
              </Col>
              <Col xs={10}>
                <Whisper
                  trigger="click"
                  placement="bottomStart"
                  speaker={
                    loading ? (
                      <Loader content="Loading..." />
                    ) : (
                      <Popover>
                        <CheckboxGroup
                          className="filter-sort-cg"
                          onChange={this.onFilterChanged}
                          value={_.get(this.state, 'filters')}
                          disabled={_.get(this.state, 'loadingTable')}
                        >
                          <Checkbox
                            key="notyet-processed"
                            value="notYetProcessed"
                          >
                            Not Yet Processed
                          </Checkbox>
                          <Checkbox key="highest-eng" value="highestEnglish">
                            Highest English Proficiency Level
                          </Checkbox>
                          <Checkbox
                            key="shortlist-candidate"
                            value="shortedlistCandidate"
                          >
                            Shortlisted
                          </Checkbox>
                          <Checkbox
                            key="accepted-candidate"
                            value="acceptedCandidate"
                          >
                            Accepted Candidate
                          </Checkbox>
                          <Checkbox
                            key="rejected-candidate"
                            value="rejectedCandidate"
                          >
                            Rejected Candidate Candidate
                          </Checkbox>
                        </CheckboxGroup>
                      </Popover>
                    )
                  }
                >
                  <Button appearance="default" className="filter-sort-btn">
                    {window.innerWidth < 768 ? '' : 'Filter & Sort'}{' '}
                    <img src="/icon-sort.png" alt="icon-sort" />
                  </Button>
                </Whisper>
              </Col>
            </Row>
          </div>

          {window.innerWidth > 768 &&
            this.renderTableInDesktopView(loadingTable, applications, opts)}

          {window.innerWidth <= 768 &&
            this.renderTableInMobileView(loadingTable, applications, opts)}

          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="xs"
            layout={['total', '-', '|', 'pager']}
            total={totalApplications}
            limit={10}
            activePage={page}
            onChangePage={(p: number) =>
              this.setState({ page: p }, () => {
                const filters = _.get(this.state, 'filters');

                if (filters.length > 0) {
                  this.onFilterChanged(filters);
                } else {
                  this.getApplications(selectedJobId);
                }
              })
            }
          />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  };

  renderMainContent = (): JSX.Element | null => (
    <Col
      md={24}
      sm={24}
      xs={24}
      lg={19}
      className="user-home-hiring-partner-main-content"
    >
      <Col xs={24}>
        <h1>My Overview</h1>
      </Col>

      <Col xs={24}>
        <h1>{this.renderMyOverview()}</h1>
      </Col>

      <Col xs={24}>
        <Col xs={18} style={{ paddingLeft: '5px' }}>
          <h1>My Job Listing</h1>
        </Col>
        <Col xs={6}>
          <Button
            className="btn-tg-primary add-new-job"
            style={{ float: 'right' }}
            appearance="primary"
            onClick={() => {
              this.getCompanyWorkingTypes();
              this.getEmploymentStatus();
              this.getRoleCategories();
              this.getLocations();
              this.setState({ showAddJobPostingModal: true });
            }}
          >
            <FaPlus /> &nbsp; {window.innerWidth > 425 ? 'Add New Job' : ''}
          </Button>
        </Col>
      </Col>

      <Col xs={24}>{this.renderJobListing()}</Col>
    </Col>
  );

  renderAddPostingModal = (
    { t } = this.props,
    { workingTypes, employmentStatuses, roleCategories, locations } = this
      .state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showAddJobPostingModal')}
      onClose={this.closeAddJobPostingModal}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          key="add-job-posting-form"
          ref={this.formAddPostingRef}
          model={this.formAddPostingSchemaModal}
          onChange={(v: object) =>
            this.onFormValueChanged(v, 'addPostingPayload')
          }
          formValue={_.get(this.state, 'addPostingPayload')}
          fluid
        >
          {/* Job Title */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobTitleKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                autoComplete="off"
                name="title"
                value={_.get(this.state, 'addPostingPayload.title')}
                placeholder="Enter the job title"
              />
            </Col>
          </Row>

          <br />

          {/* Role Category */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formRoleCategoryKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="roleCategoryId"
                accepter={InputPicker}
                data={_.map(roleCategories, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'addPostingPayload.roleCategoryId')}
                placeholder="Select the role category"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobLocationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                name="locationId"
                accepter={SelectPicker}
                appearance="default"
                data={_.map(locations, (m) => ({
                  label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                  value: _.get(m, 'id'),
                }))}
                onOpen={() => {
                  if (locations.length === 0) {
                    this.getLocations();
                  }
                }}
                onSearch={(keyword: string) => {
                  if (keyword !== '') {
                    this.getLocations(keyword);
                  }
                }}
                renderMenu={(menu) => {
                  if (locations.length === 0) {
                    return (
                      <p
                        style={{
                          padding: 4,
                          color: '#999',
                          textAlign: 'center',
                        }}
                      >
                        <Loader /> Loading...
                      </p>
                    );
                  }
                  return menu;
                }}
                size="sm"
                style={{ width: '100%' }}
                placeholder="Choose Location"
              />
            </Col>
          </Row>

          <br />

          {/* Working type */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyWorkingTypesKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="workingType"
                accepter={InputPicker}
                data={_.map(workingTypes, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'addPostingPayload.workingType')}
                placeholder="Select the working type"
              />
            </Col>
          </Row>

          <br />

          {/* Employment status */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyEmployStatusKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                value={_.get(this.state, 'addPostingPayload.empStatus')}
                name="empStatus"
                accepter={RadioGroup}
              >
                {employmentStatuses.map((e) => (
                  <Radio value={e.id}>{e.name}</Radio>
                ))}
              </Form.Control>
            </Col>
          </Row>

          <br />

          {/* Duration */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobDurationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                value={_.get(this.state, 'addPostingPayload.duration')}
                name="duration"
                accepter={SelectPicker}
                style={{ width: '100%' }}
                data={_.range(0, 13).map((v) => ({
                  label: v === 0 ? 'N/A (Permanent)' : `${v} Month(s)`,
                  value: v,
                }))}
              />
            </Col>
          </Row>

          <br />

          {/* Year of experience */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formYOEKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="minYoe"
                  accepter={InputPicker}
                  data={_.range(0, 21).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Min"
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="maxYoe"
                  accepter={InputPicker}
                  data={_.range(0, 21).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Max"
                />
              </Col>
            </Form.Group>
          </Row>

          <br />
          {/* Salary range */}
          <Row>
            <Col xs={24} style={{ marginBottom: '12px' }}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constant.translation.general.formSalaryRangeKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="salaryRange"
                  accepter={RangeSlider}
                  min={500000}
                  step={50000}
                  max={20000000}
                  renderTooltip={(value: number | undefined) =>
                    util.formatCurrency(value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          {/* Show/Hide salary */}
          <Row>
            <Col xs={24} style={{ marginBottom: '12px' }}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constant.translation.general.formSalaryDiscloseKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  style={{ color: 'black', fontWeight: 400, width: '100%' }}
                  name="isShowSalary"
                  accepter={InputPicker}
                  data={[
                    {
                      label: 'Yes',
                      value: 1,
                    },
                    {
                      label: 'No',
                      value: 0,
                    },
                  ]}
                  defaultValue={1}
                  value={_.get(this.state, 'addPostingPayload.isShowSalary')}
                  placeholder="Show Salary?"
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          {/* Custom Technical Test problem */}
          <Row>
            <Form.Group>
              <Form.ControlLabel style={{ marginBottom: '8px' }}>
                {t(constant.translation.general.formCustomTechnicalTest)}
              </Form.ControlLabel>
              <Form.Control
                name="technicalTest"
                placeholder="Enter your custom technical test (URL)"
              />
            </Form.Group>
          </Row>

          <br />

          {/* Responsibility */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formJobResponsibilityKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={24}>
                <ReactQuill
                  ref={(e) => {
                    this.responsibilityRef = e?.getEditor();
                  }}
                  style={{ border: '1px solid #b8b8b8' }}
                  theme="snow"
                  value={_.get(this.state, 'addPostingPayload.description')}
                  onChange={(value: any) => {
                    this.setState({
                      addPostingPayload: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.addPostingPayload,
                        description: value,
                      },
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Row>

          <br />
          {/* Job Requirement */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formJobRequirementKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={24}>
                <ReactQuill
                  ref={(e) => {
                    this.requirementRef = e?.getEditor();
                  }}
                  style={{ border: '1px solid #b8b8b8' }}
                  theme="snow"
                  value={_.get(this.state, 'addPostingPayload.requirements')}
                  onChange={(value: any) => {
                    this.setState({
                      addPostingPayload: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.addPostingPayload,
                        requirements: value,
                      },
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.closeAddJobPostingModal} appearance="subtle">
          Cancel
        </Button>
        <Button onClick={this.submitJobPosting} appearance="primary">
          Upload Job
        </Button>
      </Modal.Footer>
    </Modal>
  );

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderSideTabs()}
          {this.renderMainContent()}
          {this.renderModal()}
          {this.renderShortlistModal()}
          {this.renderAddPostingModal()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerDashboardScreen));
